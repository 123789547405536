import 'bootstrap';
import angular from 'angular';
// we import it here to use in lbServices
import _ngResource from 'angular-resource';
import lbServices from './services/lb-services';
import { routes } from './routes';
// I don't know how to import angular-file-upload correctly
// it should return a "string" but returns an empty Object {}
// so I just import it and pass its name
import _angularFileUpload from 'angular-file-upload';
import monospacedElastic from 'angular-elastic';
import { angularSpinner } from 'angular-spinner';
import ngCookies from 'angular-cookies';
import ngSanitize from 'angular-sanitize';
import '../../node_modules/qrcode-generator/qrcode_UTF8';
import ngQrcode from 'angular-qrcode';
import uiTree from 'angular-ui-tree';
// _minicolors the same as _angularFileUpload
// TODO: don't we use colorPicker for the same purpose?
import _minicolors from 'angular-minicolors';
import ngAnimate from 'angular-animate';
import pascalprechtTranslate from 'angular-translate';
import _angularTranslateLoaderStaticFiles from 'angular-translate-loader-static-files';
import ngIpAddress from 'ng-ip-address';
import btorfsMultiselect from 'angular-bootstrap-multiselect';
// _datePicker the same as _angularFileUpload
// TODO: replace this datepicker module with another without "moment" in dependencies
// the needed version of this datepicker isn't present in NPM so we use the one from vendor
// https://github.com/g00fy-/angular-datepicker
import _datePicker from '../vendor/angular-datepicker/dist/angular-datepicker';
import toastr from 'angular-toastr';
import colorPicker from 'angularjs-color-picker';
import uiSelect from 'ui-select';
import uiBootstrap from 'angular-ui-bootstrap';
import infiniteScroll from 'ng-infinite-scroll';
import { constants } from './constants';
import angularGoogleAnalytics from 'angular-google-analytics';

export default angular
  .module('app', [
    lbServices,
    routes,
    'angularFileUpload',
    monospacedElastic,
    angularSpinner.name,
    ngCookies,
    ngSanitize,
    ngQrcode,
    uiTree,
    'minicolors',
    ngAnimate,
    pascalprechtTranslate,
    ngIpAddress,
    btorfsMultiselect,
    'datePicker',
    toastr,
    colorPicker.name,
    uiSelect,
    uiBootstrap,
    infiniteScroll,
    constants
  ])
  .config([
    '$locationProvider',
    '$translateProvider',
    'toastrConfig',
    '$provide',
    '$cookiesProvider',
    function ($locationProvider, $translateProvider, toastrConfig, $provide, $cookiesProvider) {
      $cookiesProvider.defaults = {
        domain: window.location.hostname,
        expires: (function (span) {
          const ts = new Date();
          ts.setMonth(ts.getMonth() + span);
          return ts;
        })(1)
      };

      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
      });

      $translateProvider
        .useStaticFilesLoader({
          prefix: '/i18n/locale-',
          suffix: '.json'
        })
        .preferredLanguage('en')
        .fallbackLanguage('fr')
        .useSanitizeValueStrategy('sanitizeParameters');

      angular.extend(toastrConfig, {
        positionClass: 'toast-top-center',
        newestOnTop: false
      });

      $provide.decorator('LoopBackAuthRequestInterceptor', [
        '$delegate',
        '$q',
        'LoopBackAuth',
        '$rootScope',
        '$location',
        '$cookies',
        'CookieManager',
        function ($delegate, $q, LoopBackAuth, $rootScope, $location, $cookies, CookieManager) {
          var authHeader = 'authorization';
          var urlBase = '/api'; // just like in lb-services
          var urlBaseHost = getHost(urlBase) || location.host;

          function getHost(url) {
            var m = url.match(/^(?:https?:)?\/\/([^/]+)/);
            return m ? m[1] : null;
          }

          return {
            request: function (config) {
              // filter out external requests
              var host = getHost(config.url);
              if (host && host !== urlBaseHost) {
                return config;
              }

              if (LoopBackAuth.accessTokenId) {
                config.headers[authHeader] = LoopBackAuth.accessTokenId;
              } else if (config.__isGetCurrentUser__) {
                // Return a stub 401 error for User.getCurrent() when
                // there is no user logged in
                var res = {
                  body: { error: { status: 401 } },
                  status: 401,
                  config: config,
                  headers: function () {
                    return undefined;
                  }
                };
                return $q.reject(res);
              }
              return config || $q.when(config);
            },
            responseError: function (rejection) {
              if (rejection.status == 401) {
                $rootScope.currentUser = null;
                CookieManager.removeCookies();
                $location.path('/login');
              }
              return $q.reject(rejection);
            }
          };
        }
      ]);
    }
  ]);

