import { log } from '../../../vendor/exceljs/exceljs';
import app from '../../app';
import { objectify, deObjectify, flatWoods } from '../../helpers';

app.controller('tagsModalCtrl', [
  '$rootScope',
  '$scope',
  '$uibModalInstance',
  '$filter',
  '$location',
  '$anchorScroll',
  'companyTags',
  'enabledIds',
  'restrictedNote',
  function (
    $rootScope,
    $scope,
    $uibModalInstance,
    $filter,
    $location,
    $anchorScroll,
    companyTags,
    enabledIds,
    restrictedNote
  ) {
    const vm = this,
      tryDecode = $filter('decodeURIComponent'),
      structurize = function (flatTags) {
        const copy = function (tag) {
            return {
              id: tag.id,
              tagId: tag.tagId,
              title: tag.title,
              title_fr: tag.title_fr,
              restricted: tag.restricted,
              archived: tag.archived,
              sensitive: tag.sensitive,
              administrative: tag.administrative,
              tags: tag.tags || []
            };
          },
          tags = flatTags.map(copy),
          tagsPivot = tags.reduce(function (sum, tag) {
            sum[tag.id] = tag;
            return sum;
          }, {});
        tags.forEach(function (tag) {
          const tagChecked = enabledIds.some(function (el) {
            return el === tag.id;
          });
          const restrictedTags = $rootScope.currentUser.restrictedTags || [];

          if (restrictedTags.indexOf(tag.id) !== -1) {
            tag.disabled = true;
          } else if (tagChecked) {
            tag.disabled = !$rootScope.accessRemoveTags();
          } else {
            tag.disabled = !$rootScope.accessAddTags();
          }

          if (tag.tagId) {
            tags.forEach(function (parentTag) {
              if (parentTag.id == tag.tagId) {
                const copyTag = copy(tag);
                const tagChecked = enabledIds.some(function (el) {
                  return el === copyTag.id;
                });

                let isRestricted = restrictedTags.indexOf(copyTag.id) !== -1;
                if (isRestricted) {
                  copyTag.disabled = true;
                } else if (tagChecked) {
                  copyTag.disabled = !$rootScope.accessRemoveTags();
                } else {
                  copyTag.disabled = !$rootScope.accessAddTags();
                }
                parentTag.tags.push(copyTag);
              }
            });
          }
        });
        return [
          tags.filter(function (tag) {
            return !tag.tagId;
          }),
          tagsPivot
        ];
      };
    vm.isAdmin = $rootScope.checkIfUserIs(['admin', 'sdsEditor']);
    vm.restrictedNote = tryDecode(restrictedNote || '');
    vm.restrictedTag = companyTags.filter(function (tag) {
      return tag.restricted;
    })[0];
    vm.companyTags = companyTags;
    [vm.tags, vm.tagsPivot] = structurize(companyTags);
    vm.enabledIds = objectify(enabledIds);
    vm.cancel = $uibModalInstance.dismiss;
    vm.openedTagsTree = {};
    vm.focusedTags = {};
    vm.allTagsSelected = false;
    vm.allTagsSelectedDisabled = !$rootScope.accessRemoveTags() || !$rootScope.accessAddTags();
    vm.openTagsTree = function (tag) {
      if (tag.tags && tag.tags.length > 0) {
        vm.openedTagsTree[tag.id] = true;
      }
      if (tag.tagId && tag.tagId !== '') {
        vm.openTagsTree(vm.tagsPivot[tag.tagId]);
      }
    };
    vm.focusTag = function (tag) {
      vm.openTagsTree(vm.tagsPivot[tag.id]);
      vm.focusedTags[tag.id] = true;
      let newScrollLocation = 'tag' + tag.id;
      if ($location.hash() !== newScrollLocation) {
        $location.hash(newScrollLocation);
      } else {
        $anchorScroll();
      }
    };
    vm.toggle = function (scope) {
      let tag = scope.$modelValue;
      vm.openedTagsTree[tag.id] = scope.collapsed;
      scope.toggle();
    };
    vm.save = function () {
      $uibModalInstance.close({
        ids: deObjectify(vm.enabledIds),
        restricted: vm.restrictedTag ? vm.enabledIds[vm.restrictedTag.id] : null,
        restrictedNote: vm.restrictedNote
      });
    };

    vm.onTagCheckboxChange = tag => {
      checkParentTagIfAllChildrenAreSelected(tag);
      vm.areAllTagsSelected();
    };

    vm.areAllTagsSelected = () => {
      vm.allTagsSelected = vm.researchAllTagsSelection(vm.tags);
      return vm.allTagsSelected;
    };

    vm.researchAllTagsSelection = tags => {
      let tagsToCheck = tags.filter(tag => !isSpecialTag(tag));
      return (
        tagsToCheck.length > 0 &&
        tagsToCheck.every(
          tag =>
            (tag.tags && tag.tags.length && vm.researchAllTagsSelection(tag.tags)) ||
            vm.enabledIds[tag.id]
        )
      );
    };
    vm.toggleSelectionAll = (bool, tags = null) => {
      const toggleTags = tags || vm.tags;
      toggleTags.forEach(tag => {
        if (!isSpecialTag(tag)) {
          if (tag.tags && tag.tags.length) {
            tag.parentChecked = bool;
            vm.toggleSelectionAll(bool, tag.tags);
          } else if (!tag.disabled) {
            vm.enabledIds[tag.id] = bool;
          }
        }
      });
    };

    vm.toggleParentTag = function (parentTag) {
      toggleChildrenTags(parentTag.tags, parentTag.parentChecked);
    };

    const flatStructurizedTags = flatWoods(vm.tags, 'tags');

    init();

    function init() {
      const allTagsSelectedInitially = vm.areAllTagsSelected(); //toogle if all selected by default

      if ($rootScope.accessRemoveTags() && allTagsSelectedInitially) {
        vm.allTagsSelectedDisabled = false;
      }

      if ($rootScope.accessAddTags() && !allTagsSelectedInitially) {
        vm.allTagsSelectedDisabled = false;
      }

      for (const tag of flatStructurizedTags) {
        checkParentTagIfAllChildrenAreSelected(tag);
      }
    }

    /**
     * Secial tag check
     *
     * @param {Object} tag Tag data
     * @returns boolean
     */
    function isSpecialTag(tag) {
      return tag.archived || tag.restricted || tag.sensitive || (!vm.isAdmin && tag.administrative);
    }

    function toggleChildrenTags(tags = [], status) {
      for (let tag of tags) {
        if (tag.tags && tag.tags.length) {
          tag.parentChecked = status;
          toggleChildrenTags(tag.tags, status);
          continue;
        }

        vm.enabledIds[tag.id] = status;

        vm.onTagCheckboxChange(tag);
      }
    }

    function checkParentTagIfAllChildrenAreSelected(tag) {
      let parentTag = flatStructurizedTags.find(t => t.id.toString() === tag.tagId.toString());

      if (!parentTag) return;

      const childrenWhichAreNotParents = parentTag.tags.filter(t => !t.tags || !t.tags.length);
      const childrenWhichAreParents = parentTag.tags.filter(t => t.tags && t.tags.length);

      parentTag.parentChecked =
        childrenWhichAreNotParents.every(t => vm.enabledIds[t.id.toString()]) &&
        childrenWhichAreParents.every(t => t.parentChecked);

      if (parentTag.tagId) {
        checkParentTagIfAllChildrenAreSelected(parentTag);
      }
    }
  }
]);
