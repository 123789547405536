import app from '../app';

app.filter('getSdsIdAggregate', [
  '$rootScope',
  function ($rootScope) {
    return function (doc) {
      const lang = $rootScope.siteLanguage,
        same = ~doc.language.indexOf(lang);
      // fr version won't have frVersionId it's just id
      if (lang === 'fr' && same) {
        return doc.frVersionId || doc._id.SDS || doc.enVersionId;
        // en version won't have enVersionId
      } else if (lang === 'en' && same) {
        return doc.enVersionId || doc._id.SDS || doc.frVersionId;
      }
      return (lang === 'fr' && doc.frVersionId) || doc.enVersionId || doc._id.SDS;
    };
  }
]);
