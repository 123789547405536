import angular from 'angular';
import app from '../app';

import { get } from 'lodash';

app.service('CompanyService', [
  '$q',
  '$rootScope',
  'Company',
  'CompanySetting',
  'IPWhitelist',
  'ErrorService',
  function ($q, $rootScope, Company, CompanySetting, IPWhitelist, ErrorService) {
    const simpleCatch = ErrorService.simpleCatch;
    const companySettingsPromisesCache = {};

    this.companyPromise = null;
    this.whiteListPromise = null;

    $rootScope.IPallowed = null;

    /**
     * @deprecated use getCurrentCompanyPromise instead
     */
    this.CompanySettings = function (companyId, applyToRootScope = true) {
      var self = this;

      this.companyId = companyId;
      this.settings = {};

      if (companySettingsPromisesCache[companyId]) {
        this.$promise = companySettingsPromisesCache[companyId];

        this.$promise.then(function (settings) {
          angular.extend(self.settings, settings);
        });

        return this;
      }

      if (companyId) {
        this.$promise = companySettingsPromisesCache[companyId] = CompanySetting.findById({
          id: companyId
        }).$promise;

        this.$promise.then(function (settings) {
          if (applyToRootScope) {
            $rootScope.companySettings = settings;
          }

          angular.extend(self.settings, settings);

          return settings;
        });
      } else {
        this.$promise = {
          then: function (cb) {
            const defaultSettings = (cb && cb({})) || {};

            return $q.resolve(defaultSettings);
          }
        };
      }

      return this;
    };

    this.getCurrentCompanyPromise = function (forced) {
      if (forced || !this.companyPromise) {
        this.companyPromise = setCompanyBySchoolName();
      }

      return this.companyPromise;
    };

    this.getIPWhitelistedPromise = async function () {
      await this.getCurrentCompanyPromise().$promise;

      if (!this.whiteListPromise) {
        var filter = {
          filter: { where: { companyId: $rootScope.companyId } }
        };
        this.whiteListPromise = IPWhitelist.isIPallowed(filter);
      }

      if ($rootScope.IPallowed == null) {
        const isIPallowed = await this.whiteListPromise.$promise;
        isIPWhitelisted(isIPallowed);
      }

      return this.whiteListPromise;
    };

    this.isIpWhitelisted = function () {
      if ($rootScope.IPallowed == null) {
        return this.getIPWhitelistedPromise().then(function (isIPallowed) {
          return isIPWhitelisted(isIPallowed);
        });
      } else {
        return $q.resolve($rootScope.IPallowed);
      }
    };

    this.hasProducts = function (companyId, productIds) {
      const params = {
        id: companyId,
        where: { productId: { inq: productIds } }
      };

      return Company.products
        .count(params)
        .$promise.then(function (response) {
          return response.count >= 1;
        })
        .catch(simpleCatch);
    };

    this.getCorrectCompanyId = function (company = $rootScope.currentCompany) {
      if (!company) company = {};
      if (isIndependentSdsChild(company)) return company.id;

      return company.sdsParentCompanyId || company.id;
    };

    this.getAttrLangName = function (attrName, field = 'name') {
      const name = get(
        $rootScope.companySettings,
        `attrsLang.${attrName}.${$rootScope.siteLanguage}.${field}`,
        attrName
      );
      return name !== '' ? name : attrName;
    };

    this.isIndependentSdsChild = isIndependentSdsChild;

    function setCompanyBySchoolName() {
      const sitename = ($rootScope.sitename || '').replace(/-ca$|-us$/, '');
      if (!sitename) return { $promise: $q.reject('NO_COMPANY') };

      const filterObj = {
        filter: {
          where: {
            name: { regexp: '/^' + sitename + '$/i' }
          },
          include: [
            'companyType',
            'parentSdsCompany',
            { relation: 'childSdsCompanies', scope: { include: ['settings'] } },
            'settings'
          ]
        }
      };

      return Company.findOne(filterObj, function (company) {
        if (!company) return null;
        $rootScope.currentCompany = company;
        $rootScope.companySettings = company.settings;

        $rootScope.companyId = company.id;
        $rootScope.footerText = company.footerText;
        $rootScope.poweredBy = company.poweredBy;
        $rootScope.searchPageDisclaimer = company.searchPageDisclaimer;
        $rootScope.companyStaffTitle =
          company.companyType.title === 'school' ? 'teacher' : 'manager';

        return company;
      });
    }

    function isIPWhitelisted(isIPallowed = {}) {
      if ($rootScope.companySettings.allTrafficAllowed) {
        return ($rootScope.IPallowed = true);
      }

      $rootScope.IPallowed = !!isIPallowed.IPallowed;

      return $rootScope.IPallowed;
    }

    function isIndependentSdsChild(company = $rootScope.currentCompany) {
      return !!(company && company.sdsParentCompanyId && company.independentChild);
    }
  }
]);
