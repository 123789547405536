import angular from 'angular';
import app from '../../app';

app.controller(
  'importCtrl',
  function (
    $rootScope,
    SdsUser,
    Company,
    Tag,
    ErrorService,
    SpinnerService,
    SolrDocument,
    Manufacturer,
    StorageCodes,
    DisposalCodes,
    $q,
    HazardService,
    ProcessingTemplates,
    $scope,
    ModalService,
    $state
  ) {
    const vm = this;
    const adjustTZ = function (date) {
        if (!date) {
          return date;
        }
        if (!(date instanceof Date)) {
          date = new Date(date);
        }
        const offset = date.getTimezoneOffset() * 60 * 1000;
        date.setTime(date.getTime() - offset);
        return date;
      },
      formatDate = function (date) {
        if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
          return date;
        }
        if (!(date instanceof Date)) {
          date = adjustTZ(new Date(date));
        }
        return date ? date.toISOString().split('T').shift() : null;
      },
      isValidDate = function (value) {
        return !!Date.parse(value);
      },
      getCompanyIds = function () {
        return vm.sdsCompanyId === vm.currentCompanyId
          ? [vm.sdsCompanyId]
          : [vm.sdsCompanyId, vm.currentCompanyId];
      };

    let isSdsChildCompany = false;

    vm.sdsLang = 'en';
    vm.companies = [];
    vm.sdsCompanyId = '';
    vm.currentCompanyId = '';
    vm.currentCompanyTags = [];
    vm.universalTagsList = [];
    vm.universalTags = {};
    vm.companyTags = {};
    vm.currentFile = null;
    vm.docs2edit = {};
    vm.storageCodes = [];
    vm.disposalCodes = [];
    vm.HazardService = HazardService;

    const pt = new ProcessingTemplates();
    vm.processingTemplates = pt.processingTemplates;
    vm.selectedTemplate = pt.selectedTemplate;

    vm.knownManuNames = [];
    vm.knownManu = {};
    Manufacturer.find({ filter: { fields: ['id', 'name'] } })
      .$promise.then(function (resp) {
        vm.knownManuNames = [];
        vm.knownManu = resp.reduce(function (sum, itm) {
          sum[itm.name] = itm.id;
          vm.knownManuNames.push(itm.name);
          return sum;
        }, {});
      })
      .catch(ErrorService.simpleCatch);

    vm.onUpload = function (doc) {
      doc.dirty = false;
      doc.savedAndOk = vm.isValid(doc);
      doc.issueDateValue = isValidDate(doc.issueDate) ? adjustTZ(doc.issueDate) : new Date();
      doc.synonyms = (doc.synonyms || []).join('; ');
      vm.docs2edit[doc.filename] = doc;
    };
    vm.onPickDate = function (doc) {
      if (isValidDate(doc.issueDate)) {
        doc.issueDateValue = adjustTZ(doc.issueDate);
      }
    };
    vm.onDatePicked = function (doc) {
      doc.issueDate = formatDate(doc.issueDateValue);
      vm.onChange();
    };
    vm.onRemove = function (filename) {
      delete vm.docs2edit[filename];
      if (vm.currentFile === filename) {
        vm.currentFile = null;
      }
    };
    vm.hasAny = function (docs) {
      return Object.keys(docs).length;
    };
    vm.onSave = SpinnerService.wrap(function (doc) {
      const data = {
        id: doc.id,
        chemicalFriendlyName: doc.chemicalFriendlyName,
        issueDate: formatDate(doc.issueDate),
        companyId: isSdsChildCompany ? getCompanyIds() : vm.sdsCompanyId,
        manufacturer: vm.knownManu[doc.manufacturer] || null,
        manufacturerName: doc.manufacturer || null,
        safe_handling: doc.safe_handling || null,
        synonyms: (doc.synonyms || '')
          .split(/[;/]/)
          .map(function (itm) {
            return itm.trim();
          })
          .filter(function (itm) {
            return itm;
          }),
        storageCodes: (doc.storageCode || {}).id || null,
        disposalCodes: (doc.disposalCode || {}).id || null,
        hazardType: Object.keys(doc.hazards || {}).reduce(function (sum, type) {
          const checked = doc.hazards[type];
          return checked ? sum.concat(type) : sum;
        }, []),
        tag: Object.keys(vm.universalTags)
          .reduce(function (sum, tagId) {
            const tag = vm.universalTags[tagId];
            return tag.checked ? sum.concat(tagId) : sum;
          }, [])
          .concat(
            Object.keys(vm.companyTags).reduce(function (sum, tagId) {
              const tag = vm.companyTags[tagId];
              return tag.checked && tag.companyId === vm.currentCompanyId ? sum.concat(tagId) : sum;
            }, [])
          ),
        language: []
      };
      if (doc.restricted && vm.restrictedTag) {
        const field = 'restricted_in_' + vm.currentCompanyId;
        data[field] = doc.restrictedText || '';
        data.tag.push(vm.restrictedTag.id);
      }
      data.language.push(vm.sdsLang);

      if (!doc.id) {
        return $q.resolve(ErrorService.simpleCatch('document has no id'));
      }
      return SolrDocument.changeById(data)
        .$promise.then(function (resp) {
          doc.dirty = false;
          doc.savedAndOk = true;
          return resp;
        })
        .then(function () {
          return SolrDocument.notify(
            { actionName: 'added' },
            {
              companyIds: getCompanyIds(),
              sdsIds: [doc.id]
            }
          ).$promise;
        });
    });

    vm.isValid = function (data) {
      return (
        data.chemicalFriendlyName &&
        !!Date.parse(data.issueDate) &&
        (!data.manufacturer || !!~vm.knownManuNames.indexOf(data.manufacturer))
      );
    };
    vm.onChange = function () {
      vm.docs2edit[vm.currentFile].dirty = true;
    };

    vm.datePicker = {
      options: {
        maxDate: new Date(),
        showWeeks: false
      },
      defaultFormat: 'yyyy-MM-dd',
      altInputFormats: [
        'MM dd, yy',
        'MMMM dd, yy',
        'MMMM dd, yyyy',
        'yyyy-MM-dd',
        'dd-MMMM-yyyy',
        'yyyy/MM/dd',
        'dd.MM.yyyy',
        'shortDate'
      ],
      show: false
    };

    vm.getCompanies = SpinnerService.wrap(function () {
      if ($rootScope.checkIfUserIs('admin')) {
        return Company.find({
          filter: {
            fields: ['id', 'name', 'sdsParentCompanyId', 'independentChild']
          }
        })
          .$promise.then(function (resp) {
            vm.companies = resp;
          })
          .catch(ErrorService.simpleCatch);
      } else if ($rootScope.checkIfUserIs('sdsEditor')) {
        return SdsUser.findById({
          id: $rootScope.currentUser.id,
          filter: { include: 'allowedCompanies' }
        }).$promise.then(user => {
          vm.companies = user.allowedCompanies || [];
        });
      } else {
        return Promise.reject('Forbidden');
      }
    });

    vm.getUniversalTags = SpinnerService.wrap(function () {
      return Tag.find({
        filter: {
          where: {
            companyId: ''
          }
        }
      })
        .$promise.then(function (resp) {
          vm.universalTagsList = resp;
        })
        .catch(ErrorService.simpleCatch);
    });

    vm.getTags = SpinnerService.wrap(function (companyId) {
      return Tag.find({
        filter: {
          where: {
            companyId: companyId
          }
        }
      })
        .$promise.then(function (resp) {
          vm.currentCompanyTags = resp;
          vm.restrictedTag = resp.reduce(function (found, tag) {
            return found || (tag.restricted ? tag : null);
          }, null);
        })
        .catch(ErrorService.simpleCatch);
    });

    vm.getCodes = function (companyId) {
      const filter = {
        filter: {
          where: {
            companySettingId: companyId,
            isEnabled: true
          }
        }
      };
      return $q
        .all({
          storageCodes: StorageCodes.find(filter).$promise,
          disposalCodes: DisposalCodes.find(filter).$promise
        })
        .then(function (resp) {
          vm.storageCodes = resp.storageCodes.length ? resp.storageCodes : [];
          vm.disposalCodes = resp.disposalCodes.length ? resp.disposalCodes : [];
        })
        .catch(ErrorService.simpleCatch);
    };

    vm.onCompanySelected = function (company) {
      const companyId = company.id;
      vm.sdsCompanyId = company.sdsParentCompanyId || company.id;
      vm.currentCompanyId = companyId;
      vm.currentCompanyTags = [];
      vm.restrictedTag = null;
      vm.companyTags = {};
      vm.storageCodes = [];
      vm.disposalCodes = [];
      isSdsChildCompany = !!company.sdsParentCompanyId;

      return $q.all([vm.getTags(companyId), vm.getCodes(companyId)]);
    };

    vm.onFileSelected = function (filename) {
      vm.currentFile = filename;
    };

    vm.onEditTabSelected = function () {
      const files = Object.keys(vm.docs2edit);
      if (!vm.currentFile && files.length) {
        vm.currentFile = files[0];
      }
    };

    vm.toggleUniversalTag = function (tag) {
      vm.universalTags[tag.id] = tag;
    };
    vm.toggleCompanyTag = function (tag) {
      vm.companyTags[tag.id] = tag;
    };

    let skipPrompt = false;
    $scope.$on('$stateChangeStart', function (event, toState, toParams) {
      if (skipPrompt) {
        return;
      }
      event.preventDefault();
      const docs2delete = [];
      angular.forEach(vm.docs2edit, function (doc, filename) {
        if (!vm.isValid(doc) || !doc.savedAndOk) {
          docs2delete.push(doc);
        }
      });
      if (!docs2delete.length) {
        skipPrompt = true;
        return $state.go(toState, toParams);
      }
      ModalService.open({
        keyboard: true,
        backdrop: 'static',
        controllerAs: 'ctrl',
        controller: function () {},
        templateUrl: require('../../../views/docs2deletePrompt.html'),
        resolve: {}
      })
        .then(function (deleteFiles) {
          if (!deleteFiles) {
            skipPrompt = true;
            return $state.go(toState.name, toParams);
          }
          const deleteDocs = SpinnerService.wrap(function (docs) {
            return docs.reduce(function (promise, doc) {
              return promise.then(function () {
                return SolrDocument.deleteById({ id: doc.id }).$promise;
              });
            }, $q.when(true));
          });
          return deleteDocs(docs2delete)
            .then(function () {
              skipPrompt = true;
              return $state.go(toState.name, toParams);
            })
            .catch(ErrorService.simpleCatch);
        })
        .catch(angular.noop);
    });

    vm.getCompanies().then(function () {
      return vm.getUniversalTags();
    });
  }
);
