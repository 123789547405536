import angular from 'angular';
import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset } from '../../helpers';
import { get } from 'lodash';

app.controller('checkedDateReportCtrl', [
  '$rootScope',
  '$scope',
  'CompanyFactory',
  'CompanyService',
  'SolrDocument',
  'ErrorService',
  'ExportService',
  'SpinnerService',
  'HazardService',
  'Tag',
  'TagFactory',
  'InventoryService',
  'SchedulerService',
  'ModalService',
  function (
    $rootScope,
    $scope,
    CompanyFactory,
    CompanyService,
    SolrDocument,
    ErrorService,
    ExportService,
    SpinnerService,
    HazardService,
    Tag,
    TagFactory,
    InventoryService,
    SchedulerService,
    ModalService
  ) {
    const vm = this;
    vm.HazardService = HazardService;
    vm.totalItems = 0;

    function init() {
      vm.dataInit = true;
      vm.startFrom = 1;
      vm.PER_PAGE = 20;
      vm.companies = [];
      vm.docs = [];
      vm.selectedCompany = {};
      vm.companySettings = {};
      vm.invTree = {};

      vm.searchCriteria = {
        day: new Date(),
        span: null,
        msdsFormat: null,
        showSDSTags: false,
        showSDSInventory: false
      };

      vm.companyTags = new TagFactory(Tag);
      vm.UniversalTags = new TagFactory(Tag);
      vm.UniversalTags.getTags('', handleLoadedTags, null, true);

      if ($rootScope.checkIfUserIs('admin')) {
        CompanyFactory.GetCompanies(function (companies) {
          vm.companies = companies;
        });
      } else {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected($rootScope.currentCompany);
        });
      }
    }

    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      if (!vm.dataInit) {
        init();
      }
    });

    vm.onSearch = function (day, span, msdsFormat, showSDSTags, showSDSInventory) {
      vm.searchCriteria = {
        day: day,
        span: span,
        msdsFormat: msdsFormat,
        showSDSTags: showSDSTags,
        showSDSInventory
      };
      vm.count().then(function (amount) {
        amount > 0 ? vm.retrieve(vm.PER_PAGE, 0) : (vm.docs = []);
      });
    };

    vm.find = function (criteria, limit, offset) {
      const params = angular.extend({ limit: limit, offset: offset }, criteria);
      return SolrDocument.findByCheckedDate(params)
      .$promise
      .then(data => {
        for (let i = 0; i < data.length; i++) {
          data[i].inventoryList = (data[i].inventories || [])
            .map(invId => get(vm.invTree, `${ invId }.path`, null))
            .filter( v => v );
        }
        return data;
      })
      .catch(ErrorService.simpleCatch);
    };

    vm.retrieve = SpinnerService.wrap(function (limit, offset) {
      const companyId = CompanyService.getCorrectCompanyId(vm.selectedCompany);
      const params = angular.extend({ companyId }, vm.searchCriteria);

      const tag = filterByTag();
      if (tag.length > 0) params.tag = tag;

      return vm.find(params, limit, offset).then(function (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].tag) {
            data[i].universalTag = data[i].tag.reduce(function (acc, el) {
              for (let f = 0; f < vm.UniversalTags.tags.length; f++) {
                if (vm.UniversalTags.tags[f].id === el) {
                  acc.push(vm.UniversalTags.tags[f]);
                  break;
                }
              }
              return acc;
            }, []);
            data[i].companyTag = data[i].tag.reduce(function (acc, el) {
              for (let f = 0; f < vm.companyTags.allCompanyTags.length; f++) {
                if (vm.companyTags.allCompanyTags[f].id === el) {
                  acc.push(vm.companyTags.allCompanyTags[f]);
                  break;
                }
              }
              return acc;
            }, []);
          } else data[i].universalTag = [];
        }
        vm.docs = data;
      });
    });

    vm.count = SpinnerService.wrap(function () {
      const companyId = CompanyService.getCorrectCompanyId(vm.selectedCompany);
      const params = angular.extend({ companyId }, vm.searchCriteria);

      const tag = filterByTag();
      if (tag.length > 0) params.tag = tag;

      return SolrDocument.countByCheckedDate(params)
        .$promise.then(function (resp) {
          vm.totalItems = resp.count;
          return resp.count;
        })
        .catch(ErrorService.simpleCatch);
    });

    vm.handleExport = SpinnerService.wrap(function () {
      const criteria = vm.searchCriteria;
      const params = angular.extend({ companyId: vm.selectedCompany.id }, criteria);

      const tag = filterByTag();
      if (tag.length > 0) params.tag = tag;

      return vm
        .find(params, 2147483647 /*max int32*/, 0)
        .then(function (data) {

          //add tags path to data
          if (criteria.showSDSTags) {
            const tagsById = vm.companyTags.sortById();
            data.forEach(el => {
              el.tagsPath = [];
              (el.tag || []).forEach(tagId => {
                if (tagsById[tagId]) {
                  el.tagsPath.push([...(tagsById[tagId].path || []), tagsById[tagId].title].join(' / '));
                }
              });
            });
          }
          return ExportService.exportCheckedDateReport2csv(data, criteria);
        })
        .catch(ErrorService.simpleCatch);
    });

    vm.handlePaginate = function () {
      vm.retrieve(vm.PER_PAGE, getOffset(vm.startFrom, vm.PER_PAGE));
    };

    vm.onCompanySelected = async function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 1;
      await CompanyService.CompanySettings(selectedCompany.id, false).$promise.then(settings => {
        vm.companySettings = settings;
      });
      await vm.companyTags.getTags(selectedCompany.id, null, null, true);
      await InventoryService.getTreeInfo(selectedCompany.id)
        .then(invTree => {
          vm.invTree = invTree;
        });
      vm.count().then(function (amount) {
        amount > 0 ? vm.retrieve(vm.PER_PAGE, 0) : (vm.docs = []);
      });
    };

    vm.onChangeFilterTag = function () {
      vm.onCompanySelected(vm.selectedCompany);
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res => {
        if ($rootScope.checkIfUserIs('admin') && (!vm.selectedCompany || !vm.selectedCompany.id)) {
          return SchedulerService.createReportForAllCompanies(
            res,
            vm.companies,
            {
              msdsFormat: !!vm.searchCriteria.msdsFormat,
              showSDSTags: !!vm.searchCriteria.showSDSTags,
              showSDSInventory: !!vm.searchCriteria.showSDSInventory,
              tags2exclude: filterByTag().map(tag => tag.replace(/^-tag:/, ''))
            },
            createScheduledReport
          );
        } else {
          return createScheduledReport(res, $rootScope.companyId, {
            msdsFormat: !!vm.searchCriteria.msdsFormat,
            showSDSTags: !!vm.searchCriteria.showSDSTags,
            showSDSInventory: !!vm.searchCriteria.showSDSInventory,
            tags2exclude: filterByTag().map(tag => tag.replace(/^-tag:/, ''))
          });
        }
      });
    };

    function filterByTag() {
      const tag = [];
      if (typeof vm.radioModel === 'object') {
        const keys = Object.keys(vm.radioModel);
        for (let i in keys) {
          const pressed = vm.radioModel[keys[i]];
          // tag must be excluded from the query
          if (pressed) tag.push('-tag:' + keys[i]);
        }
      }
      return tag;
    }

    function handleLoadedTags() {
      let excludeTagIds = [];

      if ($rootScope.accessLocalVersion('CA')) {
        excludeTagIds = ['5e87678ae0c76d01000ac8ad']; // Health Canada COVID-19 List
      }

      vm.UniversalTags.tags = (vm.UniversalTags.tags || []).filter(
        tag => !excludeTagIds.includes(tag.id)
      );

      checkTags();
    }

    function checkTags() {
      vm.radioModel = {};
      for (let i in vm.UniversalTags.tags) {
        vm.radioModel[vm.UniversalTags.tags[i].id] = true;
      }
    }

    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      return SchedulerService.createReport(
        SchedulerService.jobs.report.CHECKED_DATE_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
