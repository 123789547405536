import angular from 'angular';
import app from '../../app';
import { getOffset, createTree, pivotArray } from '../../helpers';

app.controller('qrcodeCtrl', [
  '$scope',
  '$rootScope',
  '$filter',
  '$state',
  'SolrDocument',
  'QRCode',
  'Tag',
  'SearchPageService',
  'TagFactory',
  'EquipmentService',
  'QuizService',
  'MessagesService',
  'FileUploader',
  'Container',
  'ErrorService',
  'CompanyService',
  'InventoryService',
  function (
    $scope,
    $rootScope,
    $filter,
    $state,
    SolrDocument,
    QRCode,
    Tag,
    SearchPageService,
    TagFactory,
    EquipmentService,
    QuizService,
    MessagesService,
    FileUploader,
    Container,
    ErrorService,
    CompanyService,
    InventoryService
  ) {
    //create instances of TagFactory for our viewmodel
    var UniversalTags = new TagFactory(Tag);
    var CompanyTags = new TagFactory(Tag);

    var generateUniqueID = function () {
      return $filter('generateUniqueID')(10) + '_';
    };
    const simpleCatch = ErrorService.simpleCatch;

    //setup the viewmodel
    var vm = this;
    vm.sps = SearchPageService;
    vm.UniversalTags = UniversalTags;
    vm.CompanyTags = CompanyTags;
    vm.tagLookup = {};
    vm.qrcodeStart = 0;
    vm.startFrom = 1;
    vm.PER_PAGE = 10;

    $scope.filter = $filter;
    $scope.searchByParentTags = false;

    //keep a list of the ids in the qr code so we don't add duplicates
    var qrDocumentIds = [];

    //array of qrcodes
    $scope.qrcodes = [];

    //setup scope
    $scope.qrdocuments = [];

    //current QR Code
    $scope.selectedQRCode = {};

    //company Settings
    $scope.companySettings = {};

    //inventory
    $scope.inventory = [];
    $scope.inventoryPivot = [];
    $scope.inventoryTree = [];

    //init qr code settings
    $scope.v = 4;
    $scope.e = 'M';
    $scope.s = 274;

    var alertTimeout;
    var existsAlertTimeout;

    var uploader = (vm.uploader = new FileUploader({
      url:
        '/api/containers/qrcode_images/uploadFile?access_token=' + $rootScope.currentUser.tokenId,
      removeAfterUpload: true,
      formData: [{ key: 'value' }]
    }));

    var uploaderEditing = (vm.uploaderEditing = new FileUploader({
      url:
        '/api/containers/qrcode_images/uploadFile?access_token=' + $rootScope.currentUser.tokenId,
      removeAfterUpload: true,
      formData: [{ key: 'value' }]
    }));

    var fileFormatFilter = {
      name: 'fileFormatFilter',
      fn: function (item) {
        return /\.(?:jpe?g|png)$/i.test(item.name);
      }
    };

    uploader.filters.push(fileFormatFilter);
    uploaderEditing.filters.push(fileFormatFilter);

    uploader.onAfterAddingFile = function (item) {
      onAfterAddingFileHandler(item, $scope.newQRCode);
    };

    uploaderEditing.onAfterAddingFile = function (item) {
      onAfterEditFileHandler(item, $scope.selectedQRCode);
    };

    uploader.onWhenAddingFileFailed = uploaderEditing.onWhenAddingFileFailed = function (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) {
      var errors = {
        fileFormatFilter: function () {
          MessagesService.warning('UPLOAD.ALERTS.FILE_TYPE', {
            types: 'jpeg, jpg or png'
          });
        },
        fileDimensionFilter: function () {
          MessagesService.warning('UPLOAD.ALERTS.IMG_DIMENSION', {
            size: '200px'
          });
        }
      };

      errors[filter.name]();
    };

    vm.QRcodeVisibility = {
      all: ['teamMember', 'teamAdmin', 'teacher', 'admin'],
      hide: ['admin'],
      only: ['teamAdmin', 'teacher']
    };

    //company
    $scope.currentCompanyId = '';

    $scope.isDocumentAlreadyInQR = function (document) {
      for (var i = 0; i < $scope.qrdocuments.length; i++) {
        if ($scope.qrdocuments[i].id === document.id) return i;
      }

      return -1;
    };

    $scope.getInventory = async function (companyId) {
      $scope.inventory = await InventoryService.getWithProductIds(companyId);
      $scope.inventoryPivot = pivotArray($scope.inventory);
      $scope.inventoryTree = createTree($scope.inventory, 'groupId', 'inventories');
    };

    /**
     * Inventory change event
     */
    $scope.inventoryChanged = function() {
      let selectedInventory = $scope.inventory.filter(inv => inv.checked && !inv.disabled).map(inv => inv.id);
      $scope.toggleInventoryToQRCode(selectedInventory);
    }

    $scope.addDocumentToQRCode = function (solrDocument, showAlerts) {
      if (qrDocumentIds.indexOf(solrDocument.id) >= 0) {
        /*if (showAlerts || showAlerts === undefined) {
                    $timeout.cancel(existsAlertTimeout)
                    $scope.documentAlreadyAdded = true;

                    existsAlertTimeout = $timeout(function() {
                        $scope.documentAlreadyAdded = false;
                    }, 2000);
                }*/

        return;
      }

      var document = {
        id: solrDocument.id,
        title: solrDocument.chemicalFriendlyName,
        filename: solrDocument.filename
      };

      $scope.qrdocuments.push(document);
      qrDocumentIds.push(solrDocument.id);
      $scope.selectedQRCode.solrDocumentIds = qrDocumentIds;
      // console.log('\n $scope.qrdocuments: ', $scope.qrdocuments);

      QRCode.upsert(
        $scope.selectedQRCode,
        function (updatedQRCode) {
          //console.log("updated qrcode : " + JSON.stringify(updatedQRCode));
          /*if (showAlerts || showAlerts === undefined) {
                    $timeout.cancel(alertTimeout)
                    $scope.documentAdded = true;

                    alertTimeout = $timeout(function() {
                        $scope.documentAdded = false;
                    }, 2000);
                }*/
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
        }
      );
    };

    $scope.toggleTagToQRCode = function (tag, status = null) {
      var qrCode = {};
      qrCode.id = $scope.selectedQRCode.id;
      qrCode.tags = $scope.selectedQRCode.tags || {};
      tag.qrcode = tag.qrcode || [];

      if (!!qrCode.tags[tag.id] === status) {
        return;
      }

      if (!qrCode.tags[tag.id]) {
        qrCode.tags[tag.id] = angular.copy(tag);
        tag.qrcode.push(qrCode.id);
      } else {
        var qrCodeIndex = tag.qrcode.indexOf(qrCode.id);

        delete qrCode.tags[tag.id];

        if (~qrCodeIndex) tag.qrcode.splice(qrCodeIndex, 1);
      }

      QRCode.upsert(
        qrCode,
        function (updatedQRCode) {
          delete tag.disabledQRTag;
          Tag.upsert(tag);
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
        }
      );
    };

    /**
     * Save QRCode inventory
     * @param {Array<String>} inventoryIds List of inventory
     */
    $scope.toggleInventoryToQRCode = function (inventoryIds) {
      var qrCode = {};
      qrCode.id = $scope.selectedQRCode.id;
      $scope.selectedQRCode.inventory = qrCode.inventory = inventoryIds;

      QRCode.upsert(qrCode);
    };

    $scope.toggleEquipmentToQRCode = function (equipment) {
      var qrCode = {};
      qrCode.id = $scope.selectedQRCode.id;
      qrCode.equipment = $scope.selectedQRCode.equipment || {};
      equipment.qrcode = equipment.qrcode || [];

      if (!qrCode.equipment[equipment.id]) {
        qrCode.equipment[equipment.id] = equipment;
        equipment.qrcode.push(qrCode.id);
      } else {
        var qrCodeIndex = equipment.qrcode.indexOf(qrCode.id);

        delete qrCode.equipment[equipment.id];

        if (~qrCodeIndex) equipment.qrcode.splice(qrCodeIndex, 1);
      }

      QRCode.upsert(
        qrCode,
        function (updatedQRCode) {
          EquipmentService.updateEquipment(equipment).then(
            function (res) {
              console.log(res);
            },
            function (err) {
              console.log('ERROR: ', err);
            }
          );
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
        }
      );
    };

    $scope.toggleQuizToQRCode = function (quiz) {
      var qrCode = {};
      qrCode.id = $scope.selectedQRCode.id;
      qrCode.quizzes = $scope.selectedQRCode.quizzes || {};
      quiz.qrcode = quiz.qrcode || [];

      if (!qrCode.quizzes[quiz.id]) {
        qrCode.quizzes[quiz.id] = quiz;
        quiz.qrcode.push(qrCode.id);
      } else {
        var qrCodeIndex = quiz.qrcode.indexOf(qrCode.id);

        delete qrCode.quizzes[quiz.id];

        if (~qrCodeIndex) quiz.qrcode.splice(qrCodeIndex, 1);
      }

      QRCode.upsert(
        qrCode,
        function (updatedQRCode) {
          QuizService.upsert(quiz).then(
            function (res) {
              console.log(res);
            },
            function (err) {
              console.log('ERROR: ', err);
            }
          );
        },
        function (err) {
          console.log('ERROR: ', err);
        }
      );
    };

    $scope.removeDocument = function (index) {
      $scope.qrdocuments.splice(index, 1);
      qrDocumentIds.splice(index, 1);

      $scope.selectedQRCode.solrDocumentIds = qrDocumentIds;

      QRCode.upsert(
        $scope.selectedQRCode,
        function (updatedQRCode) {
          //console.log("updated qrcode : " + JSON.stringify(updatedQRCode));
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
        }
      );
    };

    $scope.removeDocumentThroughSDSlist = function (document) {
      const documentIndex = $scope.isDocumentAlreadyInQR(document);
      $scope.removeDocument(documentIndex);
    };

    vm.generatePortalUrl = $rootScope.generateUrl.bind(null, 'qrcode');
    vm.isHSEmoduleEnabled = $rootScope.isModuleEnabled.bind(null, $rootScope.moduleNames.hse);

    $scope.printQRCode = function () {
      window.print();
    };

    $scope.OnCompanySelected = function (selectedCompany) {
      $scope.currentCompanyId = selectedCompany.id;
      $scope.selectedQRCode = {};
      vm.startFrom = 1;
      vm.qrcodeStart = 0;
      $scope.getQRCodes(HandleQRCodesLoaded);
      UniversalTags.getTags('', HandleUniversalTagsLoaded, $scope.currentCompanyId);
      CompanyTags.getTags(
        $scope.currentCompanyId,
        HandleCompanyTagsLoaded,
        $scope.currentCompanyId
      );
      
      CompanyService.CompanySettings($scope.currentCompanyId, false).$promise.then(settings => {
        $scope.searchByParentTags = settings.searchByParentTags;
        $scope.companySettings = settings;
        $scope.getInventory($scope.currentCompanyId);
      });
      return Promise.all([
        EquipmentService.getEquipmentsByCompany($scope.currentCompanyId).then(
          afterGettingEquipment
        ),
        QuizService.find({ companyId: $scope.currentCompanyId }).then(afterGettingQuizzes)
      ]);
    };

    //enables edit mode on a tag title
    $scope.editTitle = function (qrcode) {
      var nodeData = qrcode;
      nodeData.enableEdit = true;
      nodeData.titleEdit = nodeData.title;
      $scope.editMode = true;
    };

    //when tag editing complete, saves to the new tag title
    $scope.saveTitleEdit = function (qrcode) {
      var qrCodeToUpdate = {};

      if (!qrcode.titleEdit) return MessagesService.warning('QR_CODE.ADD_NEW.NAME_NEEDED');
      if (!qrcode.id) qrcode.id = $filter('generateUniqueID')(8);

      qrcode.enableEdit = false;
      $scope.editMode = false;

      qrCodeToUpdate.id = qrcode.id;
      qrCodeToUpdate.title = qrcode.title = qrcode.titleEdit;
      qrCodeToUpdate.picture = qrcode.picture;
      qrCodeToUpdate.solrDocumentIds = qrcode.solrDocumentIds;
      qrCodeToUpdate.equipment = qrcode.equipment;
      qrCodeToUpdate.quizzes = qrcode.quizzes;
      qrCodeToUpdate.visibility = qrcode.visibility;
      qrCodeToUpdate.tags = qrcode.tags;
      qrCodeToUpdate.companyId = qrcode.companyId = $scope.currentCompanyId;
      qrCodeToUpdate.offline = qrcode.offline;

      QRCode.upsert(
        qrCodeToUpdate,
        function (updatedQRCode) {
          qrcode.id = updatedQRCode.id;
          uploader.uploadAll();

          if ($scope.newQRCode) {
            delete $scope.newQRCode;
            $scope.qrcodes.push(qrcode);
            vm.totalItems = $scope.qrcodes.length;
          }
        },
        function (err) {
          MessagesService.error(err);
        }
      );
    };

    //end editing without updating the tag title
    $scope.cancelTitleEdit = function (qrcode) {
      var nodeData = qrcode;
      nodeData.enableEdit = false;
      $scope.editMode = false;

      if (qrcode.newQRCode) delete $scope.newQRCode;
    };

    // toggle selection for a given tag by name
    $scope.toggleTag = function (tagId) {
      UniversalTags.toggleTagSelected(tagId);
      CompanyTags.toggleTagSelected(tagId);
    };

    //removes a tag from the tags structure
    $scope.removeQRCode = function (qrcode) {
      //console.log("Delete this QRCode");
      DeleteQRCode(qrcode);
    };

    $scope.removeTag = function (tagId) {
      $scope.toggleTag(tagId);
    };

    function DeleteQRCode(qrcode, callback) {
      var deleteStr = '{"id": "' + qrcode.id + '"}';
      var deleteObj = JSON.parse(deleteStr);

      QRCode.deleteById(
        deleteObj,
        function (response) {
          for (var i = 0; i < $scope.qrcodes.length; i++) {
            if ($scope.qrcodes[i].title === qrcode.title) {
              $scope.qrcodes.splice(i, 1);
            }
          }

          vm.totalItems = $scope.qrcodes.length;
          $scope.selectedQRCode = {};

          if (qrcode.equipment) {
            Object.keys(qrcode.equipment).forEach(function (equipmentId) {
              removeQrCodeFromEquipment(qrcode.equipment[equipmentId], qrcode.id);
            });
          }

          if (qrcode.quizzes) {
            Object.keys(qrcode.quizzes).forEach(function (quizId) {
              removeQrCodeFromQuiz(qrcode.quizzes[quizId], qrcode.id);
            });
          }

          if (qrcode.picture) {
            removeFile(qrcode.picture).catch(errorMessage);
          }

          MessagesService.success('COMMON.MESSAGES.SUCCESS');
        },
        function (err) {
          console.log('ERROR : ' + JSON.stringify(err));
        }
      );
    }

    $scope.QRCodeSelected = function (qrcode) {
      $scope.selectedQRCode = qrcode;
      $scope.inventory.forEach(inv => {
        inv.checked = $scope.selectedQRCode.inventory && $scope.selectedQRCode.inventory.includes(inv.id);
      });

      $scope.selectedQRCode.picturePreview = qrcode.picture
        ? '/api/containers/qrcode_images/download/' + qrcode.picture
        : null;
      vm.selectedVisibilityName = getQrCodeVisibilityName(qrcode.visibility);

      LoadQRCodeSolrDocuments();
    };

    vm.handlePaginate = function () {
      vm.qrcodeStart = getOffset(vm.startFrom, vm.PER_PAGE);
    };

    function assignVisibilityToQRcodeById(id, visibility) {
      $scope.qrcodes.some(function (qrcode) {
        if (qrcode.id === id) {
          qrcode.visibility = angular.extend([], visibility);

          return true;
        }
      });
    }

    function afterGettingEquipment(res) {
      vm.equipmentList = Array.prototype.slice.call(res);
      vm.getEquipmentNameById = getItemNameByIdfromList(vm.equipmentList);
    }

    function afterGettingQuizzes(res) {
      vm.quizzesList = Array.prototype.slice.call(res);
      vm.getQuizTitleById = getItemNameByIdfromList(vm.quizzesList);
    }

    function getItemNameByIdfromList(items) {
      return function (id) {
        var name = '';

        items.some(function (item) {
          if (item.id === id) {
            name = item.name || item.title;
            return true;
          }
        });

        return name;
      };
    }

    function removeQrCodeFromEquipment(equipment, qrCodeId) {
      var qrCodeIndex = equipment.qrcode.indexOf(qrCodeId);

      if (!~qrCodeIndex) return;

      equipment.qrcode.splice(qrCodeIndex, 1);

      EquipmentService.updateEquipment(equipment);
    }

    function removeQrCodeFromQuiz(quiz, qrCodeId) {
      var qrCodeIndex = quiz.qrcode.indexOf(qrCodeId);

      if (!~qrCodeIndex) return;

      quiz.qrcode.splice(qrCodeIndex, 1);

      QuizService.upsert(quiz);
    }

    function LoadQRCodeSolrDocuments() {
      //setup a fresh array
      $scope.qrdocuments = [];
      qrDocumentIds = [];

      var options = {};
      options.fieldList = 'id,chemicalFriendlyName,resourcePath';
      options.idList = '';

      $scope.selectedQRCode.solrDocumentIds.forEach(function (id) {
        id = id.trim();
        options.idList += 'id:' + id + ' OR ';
      });

      options.idList = options.idList.replace(/ OR $/, '');
      options.start = 0;
      options.rows = 100;

      // console.log("options : ", JSON.stringify(options));
      SolrDocument.findByIdList(options).$promise.then(function (results) {
        addFilenames(results.response.docs);
        //console.log("response : " + JSON.stringify(results.response));

        results.response.docs.forEach(function (solrDocument) {
          //var document = JSON.parse("{\"id\":\"" + solrDocument.id + "\",\"title\":\"" + solrDocument.filename + "\",\"filename\":\"" + solrDocument.filename + "\"}");
          //console.log("file name " + solrDocument.filename);
          var document = JSON.parse(
            '{"id":"' +
              solrDocument.id +
              '",' +
              '"title":"' +
              solrDocument.chemicalFriendlyName +
              '",' +
              '"filename":"' +
              solrDocument.filename +
              '"}'
          );
          //console.log("document : " + JSON.stringify(document));
          $scope.qrdocuments.push(document);
          qrDocumentIds.push(solrDocument.id);
        });
      });
    }

    function addFilenames(documents) {
      documents.forEach(function (document) {
        document.filename = decodeURIComponent(document.resourcePath).replace(/^.*[\\/]/, '');
      });
    }

    $scope.createNewQRCodeClicked = function () {
      //console.log("Create new QRCode here");
      CreateEmptyQRCode();
    };

    //get all the tags
    $scope.getQRCodes = function (callback) {
      var filterStr =
        '{ "filter": { "order": "order ASC", "where" : {"companyId":"' +
        $scope.currentCompanyId +
        '"} } }';
      var filterObj = JSON.parse(filterStr);

      QRCode.find(
        filterObj,
        function (qrcodes) {
          if (callback) callback(null, qrcodes);
        },
        function (err) {
          //console.log("ERROR : " + err);
        }
      );
    };

    vm.removeQrCodePicure = function (qrcode) {
      removeFile(qrcode.picture)
        .then(function (res) {
          qrcode.picture = qrcode.picturePreview = null;

          var data = {
            id: qrcode.id,
            picture: qrcode.picture
          };

          QRCode.upsert(
            data,
            function (updatedQRCode) {
              MessagesService.success('COMMON.MESSAGES.SUCCESS');
            },
            function (err) {
              MessagesService.error(err.data.error.message);
              console.error(err);
            }
          );
        })
        .catch(errorMessage);
    };

    $scope.changeVisibility = function (visibilityName) {
      var data = {
        id: $scope.selectedQRCode.id,
        visibility: vm.QRcodeVisibility[visibilityName]
      };

      QRCode.upsert(
        data,
        function (updatedQRCode) {
          console.log('succes', updatedQRCode);

          assignVisibilityToQRcodeById(data.id, data.visibility);
        },
        function (err) {
          console.log('ERROR: ', err);
          alert('Something went wrong! See the console');
        }
      );
    };
    
    $scope.toggleParentTag = function (tag) {
      const parentTagEnabled = ($scope.selectedQRCode.tags[tag.id] && $scope.selectedQRCode.tags[tag.id].id === tag.id)
      ? true
      : false;
      
      $scope.toggleTagToQRCode(tag);
      toggleChildrenTags(tag.tags, !parentTagEnabled);
    };

    vm.toggleOffline = function () {
      var data = {
        id: $scope.selectedQRCode.id,
        offline: $scope.selectedQRCode.offline
      };

      QRCode.upsert(data)
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');
        })
        .catch(simpleCatch);
    };

    function toggleChildrenTags(tags = [], status) {
      for (let tag of tags) {
        $scope.toggleTagToQRCode(tag, status);
        if ($scope.selectedQRCode.tags[tag.id]) {
          $scope.selectedQRCode.tags[tag.id].disabledQRTag = status;
        }

        toggleChildrenTags(tag.tags, status);
      }
    }

    function getQrCodeVisibilityName(qrVisibilities = []) {
      return Object.keys(vm.QRcodeVisibility).find(visibilityName =>
        vm.QRcodeVisibility[visibilityName].every(visibility => qrVisibilities.includes(visibility))
      );
    }

    //we have all the tags
    //the reorganized the tag stucture so its all nested nicely
    function HandleQRCodesLoaded(err, qrcodes) {
      $scope.qrcodes = qrcodes;
      vm.totalItems = qrcodes.length;
      //console.log("Loaded qrcodes " + JSON.stringify(qrcodes));
      $scope.editMode = false;
      if (!qrcodes || qrcodes.length <= 0) {
        $scope.editMode = true;
        CreateEmptyQRCode();
      }
    }

    function CreateEmptyQRCode() {
      $scope.newQRCode = {
        title: '',
        picture: '',
        companyId: '',
        enableEdit: true,
        newQRCode: true,
        offline: true,
        titleEdit: '',
        solrDocumentIds: [],
        quizzes: {},
        equipment: {},
        tags: {},
        visibility: vm.QRcodeVisibility.all,
        version: 0
      };
    }

    //universal tags loaded, update the view model
    function HandleUniversalTagsLoaded() {
      vm.tagLookup = $.extend(vm.tagLookup, UniversalTags.tagLookup);
    }

    //company tags loaded, update the viewmodel
    function HandleCompanyTagsLoaded() {
      vm.tagLookup = $.extend(vm.tagLookup, CompanyTags.tagLookup);
    }

    //populate the search form with data from the previous search
    function InitilizeSearchForm() {
      vm.sps.onReset();
      // have to pre-fetch otherwise tags don't get updated after initial hydration
      vm.sps.preFetch(true).then(function () {
        const state = $state.params.state ? JSON.parse($state.params.state) : null;
        if (state) {
          vm.sps.doSearch(state);
        }
      });
    }

    function onAfterAddingFileHandler(item, qrcode) {
      setFileUniqueName(item.file);

      if (qrcode) qrcode.picture = item.file.name;

      if (uploader.queue.length > 1) uploader.removeFromQueue(0);
    }

    function onAfterEditFileHandler(item, qrcode) {
      // we should remove the old file before upload a new one
      removeFile(qrcode.picture).finally(function () {
        setFileUniqueName(item.file);

        if (uploaderEditing.queue.length > 1) uploaderEditing.removeFromQueue(0);

        item.onComplete = function (response, status) {
          if (status !== 200) {
            console.error(response);
            return MessagesService.error(response.error.message);
          }

          var qrCode = {};
          qrCode.id = qrcode.id;
          qrCode.picture = item.file.name;

          QRCode.upsert(
            qrCode,
            function (updatedQRCode) {
              qrcode.picture = updatedQRCode.picture;
              qrcode.picturePreview =
                '/api/containers/qrcode_images/download/' + updatedQRCode.picture;
            },
            function (err) {
              console.error(err);
              MessagesService.error(err);
            }
          );
        };

        uploaderEditing.uploadAll();
      });
    }

    function setFileUniqueName(file) {
      file.originalFileName = file.name;
      file.name = generateUniqueID() + file.name;
    }

    function removeFile(filename) {
      return Container.removeFile({
        container: 'qrcode_images',
        file: filename
      }).$promise;
    }

    function errorMessage(err) {
      MessagesService.error(err.data.error.message);
      console.error(err);
    }

    //setup the search page
    function Initialize() {
      InitilizeSearchForm();

      if ($rootScope.currentUser && $rootScope.currentUser.companyId) {
        CompanyTags.getTags($rootScope.currentUser.companyId, HandleCompanyTagsLoaded);
        $scope.currentCompanyId = $rootScope.currentUser.companyId;

        CompanyService.CompanySettings($scope.currentCompanyId, false).$promise.then(settings => {
          $scope.searchByParentTags = settings.searchByParentTags;
          $scope.companySettings = settings;
          $scope.getInventory($scope.currentCompanyId);
        });
      }

      $scope.getQRCodes(HandleQRCodesLoaded);
      UniversalTags.getTags('', HandleUniversalTagsLoaded);
      EquipmentService.getEquipmentsByCompany($scope.currentCompanyId).then(afterGettingEquipment);
      QuizService.find({ companyId: $scope.currentCompanyId }).then(afterGettingQuizzes);
    }

    Initialize();
  }
]);
