import app from '../../app';

app.directive('inventoryWithProductsSelector', [
  function () {
    return {
      restrict: 'E',
      scope: {
        invs: '=',
        startCollapsed: '=',
        onChange: '&',
        noFacet: '='
      },
      templateUrl: require('./inventoryWithProductsSelector.html'),
      link: function (scope) {
        scope.$watch('invs', function (invs) {
          scope.tagsTree = toogleByParent(invs);
        });
        
        function toogleByParent(items) {
          items.forEach(item => {
            if (item.checked && item.inventories && item.inventories.length) {
              toggleChildren(item.inventories, item.checked);
            } else {
              item.disabled = false;
              if (item.inventories && item.inventories.length) {
                toogleByParent(item.inventories);
              }
            }
          });
        }

        function toggleChildren(items = [], status) {
          for (let item of items) {
            item.disabled = item.checked = status;
            if (item.inventories && item.inventories.length) {
              toggleChildren(item.inventories, status);
            }
          }
        }
        scope.toggleParentInv = function (parent) {
          toggleChildren(parent.inventories, parent.checked);
          scope.onChange(parent);
        };
        scope.toggleTree = function (event, uiTreeNode) {
          if (event.target instanceof HTMLInputElement) return;
          uiTreeNode.toggle();
        };
      }
    };
  }
]);
