import app from '../app';

app.filter('getDocName', [
  '$rootScope',
  function ($rootScope) {
    const getLangName = function (doc, lang) {
      return lang === 'fr' ? doc.frName : doc.enName;
    };
    return function (doc) {
      return (
        getLangName(doc, $rootScope.siteLanguage) ||
        doc.chemicalFriendlyName ||
        doc.filename ||
        doc.id
      );
    };
  }
]);
