import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset, waitForProp } from '../../helpers';

app.controller('discontinuedReportCtrl', [
  '$rootScope',
  '$scope',
  'CompanyService',
  'SolrDocumentService',
  'ModalService',
  'ExportService',
  'SchedulerService',
  discontinuedReportCtrl
]);

function discontinuedReportCtrl(
  $rootScope,
  $scope,
  CompanyService,
  SolrDocumentService,
  ModalService,
  ExportService,
  SchedulerService
) {
  const vm = this;

  let dateFrom = null;
  let dateTo = null;

  vm.PER_PAGE = 20;
  vm.startFrom = 0;
  vm.totalItems = 0;
  vm.reportData = [];
  vm.selectedCompany = {};
  vm.rangePickerApi = {};

  const waitForGetEvalDate = waitForProp(vm.rangePickerApi, 'getEvalDate');

  const getReportData = async function (companyId, offset) {
    const response = await SolrDocumentService.getDiscontinuedSdss(companyId, {
      offset,
      limit: vm.PER_PAGE,
      dateFrom,
      dateTo
    });

    vm.totalItems = response.total;
    vm.reportData = response.docs;

    $scope.$apply();

    return response;
  };

  vm.onCompanySelected = function (selectedCompany) {
    vm.selectedCompany = selectedCompany;
    vm.startFrom = 0;

    vm.changeDate(vm.rangePickerApi.getEvalDate());
  };

  vm.handlePaginate = function () {
    getReportData(vm.selectedCompany.id, getOffset(vm.startFrom, vm.PER_PAGE));
  };

  vm.changeDate = function (date) {
    vm.startFrom = 0;
    dateFrom = date.start;
    dateTo = date.end;

    getReportData(vm.selectedCompany.id, vm.startFrom, date.start, date.end);
  };

  vm.handleExport = function () {
    ExportService.exportDiscontinuedReport2csv(vm.selectedCompany.id, dateFrom, dateTo);
  };

  vm.openScheduleModal = function () {
    return ModalService.open(scheduleReport()).then(res =>
      createScheduledReport(res, vm.selectedCompany.id)
    );
  };

  init();

  async function init() {
    await waitForGetEvalDate();

    if (!$rootScope.checkIfUserIs('admin')) {
      CompanyService.getCurrentCompanyPromise().$promise.then(function () {
        if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

        vm.onCompanySelected({ id: $rootScope.companyId });
      });
    }
  }

  function createScheduledReport(formObj, companyId, params = {}) {
    return SchedulerService.createReport(
      SchedulerService.jobs.report.DISCONTINUED_REPORT,
      companyId,
      formObj.emails,
      formObj.frequency,
      params
    );
  }
}
