import angular from 'angular';

// https://stackoverflow.com/a/33928558/4183853
// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export const copyToClipboard = function (text) {
  const clipboardData = window.clipboardData;

  if (clipboardData && clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (err) {
      console.error('Copy to clipboard failed.', err);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

/**
 * Make an array from properties that set to true of the given object
 *
 * @param {Object} obj object of properties with true/false values
 * e.g. { property1: true, property2: false, property3: true }
 * @returns {Array} returns an array with only true properties
 * e.g ['property1', 'property3']
 */
export const deObjectify = function (obj) {
  if (!obj) return [];

  return Object.keys(obj).filter(function (key) {
    return obj[key];
  });
};

/**
 * Make an object of properties with true value from the given array
 * (it actually revert deObjectify function)
 *
 * @param {Array} arr an array of strings/numbers
 * e.g. ['string', 123]
 * @returns {Object} returns an object with properties those set to true from the array items
 * e.g. {string: true, 123: true}
 */
export const objectify = function (arr) {
  if (!Array.isArray(arr)) return {};

  return arr.reduce(function (obj, item) {
    obj[item] = true;

    return obj;
  }, {});
};

/**
 * Returns an array with decoded chemical names of given documents
 *
 * @param {Array} docs - an array of documents
 * @returns {Array} - an array of documents with decoded names
 */
export const decodeDocumentsChemicalName = function (docs) {
  return docs.reduce(function (decDocs, doc) {
    var decDoc = angular.copy(doc);
    var filenameRegex = /[^\\/]*\.\w+$/;

    if (decDoc.chemicalFriendlyName) {
      decDoc.name = decodeURIComponent(decDoc.chemicalFriendlyName);
    } else if (decDoc.resourcePath) {
      decDoc.name = decDoc.resourcePath.match(filenameRegex)[0];
    }

    decDocs.push(decDoc);

    return decDocs;
  }, []);
};

/**
 * Returns number of pages
 *
 * @param {number} total total documents
 * @param {number} limit limit per page
 * @returns {number} number of pages
 */
export const calculatePages = function (total, limit) {
  return Math.ceil(total / limit);
};

/**
 * Removes the last comma from the given string
 *
 * @param {string} str the string with commas
 * @returns {string} the string without last comma
 */
export const removeLastComma = function (str) {
  return str.replace(/,\s*$/, '');
};

/**
 * Replaces undefined fields in object with empty strings
 *
 * @param {Object} obj an object with undefined
 * @returns a copy of original object with fields with empty string instead of undefined;
 */
export const replaceUndefinedFieldsWithEmptyStr = function (obj) {
  var newObj = angular.copy(obj);

  Object.keys(newObj).forEach(function (field) {
    if (newObj[field] === undefined) {
      newObj[field] = '';
    }
  });

  return newObj;
};

export const validateAmount = function (newAmount, oldAmount) {
  return newAmount == undefined || newAmount < 0 ? +oldAmount : +newAmount;
};

/**
 * Makes an object with IDs as keys from the given array with objects with ID
 *
 * @param {Array<Object>} array the array with objects with ID
 * @returns {Object} an object with IDs as keys
 */
export const makeObjectWithIdsFromArrayOfObjects = function (array) {
  return array.reduce(function (obj, item) {
    obj[item.id] = item;

    return obj;
  }, {});
};

export const isChecked = function (item) {
  if (Array.isArray(item)) {
    return item.reduce(function (sum, itm) {
      return sum && isChecked(itm);
    }, true);
  }
  return !!item.checked;
};

export const getNameFromFile = function (path, includeExtension) {
  const matches = path && typeof path.match === 'function' && path.match(/\/?([^/.]*)\.?([^/]*)$/);
  if (!matches) return null;

  if (includeExtension === true && matches.length > 2 && matches[2]) {
    return matches.slice(1).join('.');
  }

  return matches[1];
};

export const isInArray = function (arr, item, prop) {
  return (arr || []).some(function (el) {
    return prop ? el[prop] === item[prop] : el === item;
  });
};

export const clearObject = function (obj) {
  Object.keys(obj).forEach(function (key) {
    delete obj[key];
  });
};

export const waitForProp = function (obj, propName, test, timeout) {
  const limitAttempt = 20;
  let tryAttempt = 0;
  timeout = timeout || 100;

  return function wait(cb) {
    return new Promise((resolve, reject) => {
      if ((obj[propName] && !test) || (obj[propName] && test && test())) {
        return cb && cb(obj[propName]), resolve(obj[propName]);
      }

      if (tryAttempt >= limitAttempt) return reject('still no prop "' + propName + '"');

      tryAttempt++;

      setTimeout(function () {
        wait(cb).then(resolve).catch(reject);
      }, timeout);
    });
  };
};

export const deepMerge = function (obj1, obj2) {
  const newObj = {};
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  keys1.forEach(function (key) {
    const value1 = obj1[key];

    if (isPrimitive(value1)) return (newObj[key] = value1);

    if (typeof value1 === 'object') {
      if (Array.isArray(value1)) return (newObj[key] = value1.slice());

      return (newObj[key] = deepMerge(value1, {}));
    }
  });

  keys2.forEach(function (key) {
    const value1 = newObj[key];
    const value2 = obj2[key];

    if (value1 == null) return (newObj[key] = value2);

    if (isPrimitive(value1)) return (newObj[key] = value2);

    if (typeof value1 === 'object') {
      if (isPrimitive(value2)) return (newObj[key] = value2);

      if (typeof value2 === 'object') {
        if (Array.isArray(value1) && !Array.isArray(value2)) {
          return (newObj[key] = value1.concat(value2));
        } else if (!Array.isArray(value1) && Array.isArray(value2)) {
          return (newObj[key] = value2.concat(value1));
        } else if (Array.isArray(value1) && Array.isArray(value2)) {
          return value2.forEach(function (arrItem2, index) {
            if (isPrimitive(arrItem2) && !~value1.indexOf(arrItem2)) return value1.push(arrItem2);

            const arrItem1 = value1[index];

            if (typeof arrItem2 === 'object' && typeof arrItem1 === 'object') {
              if (Array.isArray(arrItem2) && Array.isArray(arrItem1))
                return (value1[index] = arrItem1.concat(arrItem2));

              return (value1[index] = deepMerge(arrItem1, arrItem2));
            }
          });
        } else {
          return (newObj[key] = deepMerge(value1, value2));
        }
      }

      console.warn(`don't know what to do with value1: `, value1, 'and value2: ', value2);
    }
  });

  return newObj;

  function isPrimitive(value) {
    return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
  }
};

export const injectDependencies = function (dependencies = []) {
  const $injector = angular.element(document.documentElement).injector();

  return dependencies.map(dependency => {
    if (dependency === '$scope' || dependency === 'scope')
      return $injector.get('$rootScope').$new();

    return $injector.get(dependency);
  });
};

export const recursivelyRenameProp = function (arr = [], oldPropName = '', newPropName = '') {
  if (!arr.length) return;

  for (let item of arr) {
    if (item[oldPropName] != null) {
      item[newPropName] = item[oldPropName];
    }

    if (Array.isArray(item[oldPropName]))
      recursivelyRenameProp(item[oldPropName], oldPropName, newPropName);

    delete item[oldPropName];
  }
};

export const anchorSmoothScroll = function (eID, topOffset = 0) {
  // This scrolling function
  // is from http://www.itnewb.com/tutorial/Creating-the-Smooth-Scroll-Effect-with-JavaScript

  var startY = currentYPosition();
  var stopY = elmYPosition(eID, topOffset);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 100);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (let i = startY; i < stopY; i += step) {
      setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }

  function currentYPosition() {
    // Firefox, Chrome, Opera, Safari
    if (self.pageYOffset) return self.pageYOffset;
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
      return document.documentElement.scrollTop;
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
  }

  function elmYPosition(eID, topOffset) {
    var elm = document.getElementById(eID);
    var y = elm.offsetTop - topOffset;
    var node = elm;
    while (node.offsetParent && node.offsetParent != document.body) {
      node = node.offsetParent;
      y += node.offsetTop;
    }
    return y;
  }
};

/**
 * Creates a tree structure from a flat list
 *
 * @param {Array<Object>} [flatTree=[]] a flat list of items
 * @param {string} [parentField='parentId'] name of the field that points to the parent item
 * @param {string} [childrenField='children'] name of the field where children items will be placed
 * @param {string} [key='id'] name of the key field, it's usually "id"
 * @returns {Array<Object>} an array of root items with children
 */
export const createTree = function (
  flatTree = [],
  parentField = 'parentId',
  childrenField = 'children',
  key = 'id'
) {
  const parentChildrenMap = {};
  const root = flatTree.filter(item => !doesHaveParent(item));
  const children = flatTree.filter(doesHaveParent);

  children.forEach(item => {
    const parentId = item[parentField];
    parentChildrenMap[parentId] = parentChildrenMap[parentId] || [];

    parentChildrenMap[parentId].push(item);
  });

  flatTree.forEach(item => {
    item[childrenField] = parentChildrenMap[item[key]] || [];
  });

  return root;

  function doesHaveParent(item) {
    return item[parentField] && item[parentField] !== 'root';
  }
};

export const findTopParent = function (items = [], child = {}, parentKey = '', propToPersist = '') {
  const _child = typeof child === 'string' ? items.find(i => i.id.toString() === child) : child;
  const parent = items.find(i => i.id === _child[parentKey]);

  if (!parent || (propToPersist && !parent[propToPersist])) return _child;

  const grandParentId = parent[parentKey];
  if (!grandParentId || grandParentId === 'root' || grandParentId === 'top') return parent;

  return findTopParent(items, parent, parentKey, propToPersist);
};

export const flatTree = function (treeNode = {}, childName = '') {
  const flat = [treeNode];

  return (treeNode[childName] || []).reduce(
    (sum, node) => sum.concat(flatTree(node, childName)),
    flat
  );
};

export const flatWoods = function (trees = [], childName = '') {
  return trees.reduce((sum, tree) => sum.concat(flatTree(tree, childName)), []);
};

/**
 * Returns a path to the tag with all of its parents as an array
 *
 * @param {Array<Resource>} companyTags
 * @param {string | ObjectId} tagId
 * @param {Array<string>} [tagPath=[]]
 * @returns {Array<string>}
 */
export const getTagPath = function (companyTags, tagId, tagPath = []) {
  const tag = companyTags.find(t => t.id.toString() === tagId.toString());
  if (!tag) return tagPath;

  tagPath.unshift(tag.title);

  if (!tag.tagId) return tagPath;

  return getTagPath(companyTags, tag.tagId, tagPath);
};

/**
 * Returns a path to the element with all of its parents as an array
 *
 * @param {Array} inventory
 * @param {string} groupId
 * @param {Array} [path=[]]
 * @returns {Array}
 */
export const getTreePath = function (list, groupId, path = []) {
  const el = list.find(t => t.id.toString() === groupId.toString());
  if (!el) return path;

  path.unshift(el.name);

  if (!el.groupId) return path;

  return getTreePath(list, el.groupId, path);
};

export const getOffset = function (page = 1, limit = 20) {
  return Math.max((page - 1) * limit, 0);
};

export const mergeBy = (arr1 = [], arr2 = [], key = 'id') =>
  !arr1.length
    ? [...arr2]
    : !arr2.length
    ? [...arr1]
    : [
        ...arr1
          .concat(arr2)
          .reduce(
            (sum, item) => sum.set(item[key], Object.assign(sum.get(item[key]) || {}, item)),
            new Map()
          )
          .values()
      ];

export const tryDecodeURIComponent = value => {
  if (!value) {
    return value;
  }

  try {
    value = decodeURIComponent(value);
  } catch (err) {
    console.error(err);
  }

  return value;
};

export const isLink = (str = '') => {
  return /^https?:\/\//i.test(str);
};

/**
 * Converts Date object, correct date string or milliseconds to seconds
 *
 * @param {Date|number|string} date Date object, correct date string or milliseconds
 * @returns {number} seconds
 */
export const getSeconds = date => {
  if (date instanceof Date || typeof date === 'number') {
    return Math.floor(date / 1000);
  } else if (typeof date === 'string') {
    return Math.floor(new Date(date) / 1000);
  } else {
    console.warn('wrong type of date', date);

    return date;
  }
};

export const reselectChildTag = (tags, tag) => {
  const parentTag = findTopParent(tags, tag, 'tagId', 'checked');
  const childTags = flatTree(parentTag, 'tags');

  if (parentTag && parentTag.checked) {
    childTags.forEach(t => {
      t.checked = false;
      t.disabled = false;
    });
  }

  tag.checked = true;
};

export const mBytesToBytes = mBytes => {
  //1 megabytes = 1000000 bytes
  return mBytes * 1000000;
};

/**
 * Make an key/value object by array
 *
 * @param {Array} arr an array of objects
 * @param {String} key object property to use as a key
 * @param {Function} cb callback function for result object element
 *
 * @returns {Object} returns an object with key/value properties
 * e.g. pivotArray([{id: 1, v: 1}, {id: 2, v: 2}], 'id', el => el.v+2);
 * returns { '1': 3, '2': 4 }
 */
export const pivotArray = (arr, key = 'id', cb = el => el) =>
  arr.reduce((sum, obj) => {
    sum[obj[key]] = cb(obj);
    return sum;
  }, {});
