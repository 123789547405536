import angular from 'angular';
import { companyDomains } from '../../common/companyDomains';
export const constants = 'constants';

angular
  .module(constants, [])
  .constant('schoolDomains', companyDomains)
  .constant('domains', {
    CA: 'canadasds.com',
    US: 'ussds.com'
  })
  .constant('moduleNames', {
    hse: 'HealthSafetyEducation',
    inv: 'Inventory',
    CERCLAReport: 'CERCLAReport',
    cers: 'ReportCERS',
    ReportTier2: 'ReportTier2',
    ReportTier2Texas: 'ReportTier2Texas',
    ReportPAHSSF: 'ReportPAHSSF',
    ReportFireResponse: 'ReportFireResponse',
    ReportWCL: 'ReportWCL',
    Section313Report: 'Section313Report'
  })
  .constant('bannedListType', {
    custom: 'custom',
    stao: 'stao',
    bc: 'bc',
    ab: 'ab',
    mb: 'mb'
  })
  .constant('MAX_INT', 2147483647 /*max int32*/)
  .constant('INV_MAX_DEPTH', 9)
  .constant('TAG_MAX_DEPTH', 9)
  .constant('topNavigation', [
    {
      cond: function () {
        return this.checkIfUserIs();
      },
      state: 'search',
      title: 'NAVIGATION.SEARCH'
    },
    {
      cond: function () {
        return (
          this.isModuleEnabled(this.moduleNames.hse) &&
          (this.checkIfUserIs(['admin', 'sdsEditor', 'firstResponder'])
            ? false
            : this.checkIfUserIs())
        );
      },
      state: 'portal',
      title: 'NAVIGATION.PORTAL'
    },
    {
      cond: function () {
        return (
          (this.isModuleEnabled(this.moduleNames.hse) || this.accessLiteQrCodes()) &&
          this.checkIfUserIs(['admin', 'teamAdmin', 'teacher'])
        );
      },
      state: 'qrcode',
      title: 'NAVIGATION.QR_CODE'
    },
    {
      cond: function () {
        return this.checkIfUserIs(['admin', 'sdsEditor']);
      },
      state: 'upload',
      title: 'NAVIGATION.UPLOAD'
    },
    {
      cond: function () {
        return this.checkIfUserIs(['admin', 'sdsEditor']);
      },
      state: 'process',
      title: 'NAVIGATION.PROCESS'
    },
    {
      cond: function () {
        return this.checkIfUserIs('admin');
      },
      state: 'tags',
      title: 'NAVIGATION.TAGS'
    },
    {
      cond: function () {
        return this.checkIfUserIs('admin');
      },
      state: 'bulkDocumentEdit',
      title: 'NAVIGATION.BULK_EDIT'
    },
    {
      cond: function () {
        return this.checkIfUserIs(['admin', 'teamAdmin']);
      },
      state: 'users',
      title: 'NAVIGATION.USERS'
    },
    {
      cond: function () {
        const requestSdsByIpEnabled = (this.companySettings || {}).requestSdsByIpEnabled;

        return (
          this.accessAddSds() &&
          !this.checkIfUserIs(['firstResponder', 'sdsEditor']) &&
          ((requestSdsByIpEnabled && this.checkIfUserIs()) || !!this.currentUser)
        );
      },
      state: 'requestSds',
      title: 'NAVIGATION.REQUEST'
    },
    {
      cond: function () {
        const locationRequestEnabled = (this.companySettings || {}).locationRequestEnabled;
        const requestLocationByIpEnabled = (this.companySettings || {}).requestLocationByIpEnabled;
        return (
          this.isModuleEnabled(this.moduleNames.inv) &&
          locationRequestEnabled &&
          !this.checkIfUserIs(['firstResponder', 'sdsEditor']) &&
          ((requestLocationByIpEnabled && this.checkIfUserIs()) || !!this.currentUser)
        );
      },
      state: 'locationRequest',
      title: 'NAVIGATION.LOCATION_REQUEST'
    },
    {
      cond: function () {
        return (
          this.isModuleEnabled(this.moduleNames.hse) &&
          this.checkIfUserIs(['admin', 'teamAdmin', 'teacher'])
        );
      },
      state: 'equipment',
      title: 'NAVIGATION.EQUIPMENT'
    },
    {
      cond: function () {
        return (
          this.isModuleEnabled(this.moduleNames.hse) &&
          this.checkIfUserIs(['admin', 'teamAdmin', 'teacher'])
        );
      },
      state: 'quiz',
      title: 'NAVIGATION.QUIZ'
    },
    {
      cond: function () {
        return this.isInventoryModuleAvailable();
      },
      state: 'inventory',
      title: 'NAVIGATION.INVENTORY'
    },
    {
      cond: function () {
        return this.isInventoryModuleAvailable();
      },
      state: 'products',
      title: 'NAVIGATION.PRODUCTS'
    },
    {
      cond: function () {
        return this.checkIfUserIs(['admin', 'sdsEditor']);
      },
      state: 'manufacturers',
      title: 'NAVIGATION.MANUFACTURERS'
    }
  ]);
