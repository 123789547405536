import app from '../../app';

app.controller('editBannedEntryCtrl', [
  '$uibModalInstance',
  '$scope',
  'BannedList',
  'entry',
  'known',
  'companySettings',
  'SpinnerService',
  '$q',
  'MessagesService',
  function (
    $uibModalInstance,
    $scope,
    BannedList,
    entry,
    known,
    companySettings,
    SpinnerService,
    $q,
    MessagesService
  ) {
    const vm = this,
      isEdit = !!entry.id,
      normalize = function (value) {
        return value.toLowerCase().replace(/["]+/g, '').replace(/\s+/g, ' ').trim();
      },
      addRecord = function (data) {
        return BannedList.create(data).$promise;
      },
      updateRecord = SpinnerService.wrap(function (data) {
        return BannedList.update({ where: { id: data.id } }, data).$promise.then(function (resp) {
          return resp.count === 1 ? data : $q.reject(`Haven't managed to update anything`);
        });
      }),
      apply = function (data) {
        return (isEdit ? updateRecord : addRecord)(data)
          .then(function (respData) {
            MessagesService.success(isEdit ? 'Updated' : 'Added');
            $uibModalInstance.close(respData);
          })
          .catch(function (err) {
            MessagesService.error(err);
          });
      };

    vm.title = isEdit ? 'Edit Banned Chemical' : 'Add Banned Chemical';
    vm.action = isEdit ? 'Save' : 'Add';
    vm.availableLanguages = companySettings.disabledMultilanguage
      ? [$scope.languages[0]]
      : $scope.languages;
    vm.language = $scope.languages[0];
    vm.entry = {
      id: entry.id,
      companyId: entry.companyId,
      name: entry.name || '',
      type: entry.type
    };
    $scope.languages.forEach(lang => {
      vm.entry[`comment_${lang}`] = entry[[`comment_${lang}`]] || '';
    });
    vm.entry[`comment_en`] = entry.comment || '';

    (vm.origName = entry.name), (vm.known = known);

    vm.dismiss = $uibModalInstance.dismiss;
    vm.apply = function () {
      vm.entry.comment = vm.entry.comment_en;
      delete vm.entry.comment_en;
      vm.entry.title = normalize(vm.entry.name);
      return apply(vm.entry);
    };
  }
]);
