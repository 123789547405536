import app from '../../app';
import firstAidMeasuresModal from '../../modals/firstAidMeasuresModal';
import ppePresentationModal from '../../modals/ppePresentationModal';

app.directive('sdsDoc', [
  '$rootScope',
  '$state',
  'LabelBatchService',
  'SolrDocument',
  'ModalService',
  'SearchPageService',
  function ($rootScope, $state, LabelBatchService, SolrDocument, ModalService, SearchPageService) {
    return {
      restrict: 'E',
      scope: {
        newTab: '=',
        doc: '=',
        hideTags: '=',
        hideManufacturers: '=',
        hideEdit: '=',
        onClick: '&',
        printState: '='
      },
      templateUrl: require('./sdsDoc.html'),
      link: function (scope, elem, attrs) {
        scope.SearchPageService = SearchPageService;
        const isAdminUser = $rootScope.checkIfUserIs('admin');
        scope.hideEdit = isAdminUser ? scope.hideEdit : true;
        scope.toggleDocInLabelBatch = LabelBatchService.toggleDocInLabelBatch;
        scope.isDocAlreadyAdded = LabelBatchService.isDocAlreadyAdded;
        scope.doesDocHaveCompanyTags = function () {
          return (scope.doc.tag || []).some(
            tag => !tag.restricted && tag.companyId === $rootScope.companyId
          );
        };
        scope.docChemicalFriendlyName = { en: '', fr: '' };
        scope.docFirstAidMeasuresInfo = { en: '', fr: '' };
        scope.showFirstAidMeasuresInfo = function () {
          ModalService.open(
            firstAidMeasuresModal(
              scope.docChemicalFriendlyName[$rootScope.siteLanguage],
              scope.docFirstAidMeasuresInfo[$rootScope.siteLanguage]
            )
          );
        };
        scope.relatedDocs = {};
        getRelatedDocs();
        if (
          !isAdminUser &&
          $rootScope.companySettings.enableFirstAidInfoInSearch &&
          scope.doc.companyId &&
          scope.doc.companyId.includes($rootScope.companyId)
        ) {
          getDocumentFirstAidMeasures();
        }
        function getDocumentFirstAidMeasures() {
          let idList;
          const siteLanguageSdsId = scope.doc.id;
          let anotherLanguageSdsId = '';
          if (scope.doc.enVersionId) {
            idList = 'id:' + scope.doc.id + ' OR id:' + scope.doc.enVersionId;
            anotherLanguageSdsId = scope.doc.enVersionId;
          } else if (scope.doc.frVersionId) {
            idList = 'id:' + scope.doc.id + ' OR id:' + scope.doc.frVersionId;
            anotherLanguageSdsId = scope.doc.frVersionId;
          } else {
            idList = 'id:' + scope.doc.id;
          }
          const options = { idList };
          SolrDocument.findByIdList(options).$promise.then(function (results) {
            const findById = id => val => val.id === id;
            if (results.response.docs.length === 1) {
              const doc = results.response.docs[0];
              const docChemicalFriendlyName = decodeURIComponent(doc.chemicalFriendlyName);
              const docFirstAidMeasuresInfo = doc.first_aid_measures
                ? decodeURIComponent(doc.first_aid_measures)
                : '';
              scope.docChemicalFriendlyName = {
                en: docChemicalFriendlyName,
                fr: docChemicalFriendlyName
              };
              scope.docFirstAidMeasuresInfo = {
                en: docFirstAidMeasuresInfo,
                fr: docFirstAidMeasuresInfo
              };
            } else {
              const doc1 = results.response.docs.find(findById(siteLanguageSdsId));
              const doc2 = results.response.docs.find(findById(anotherLanguageSdsId));
              const doc1ChemicalFriendlyName = decodeURIComponent(doc1.chemicalFriendlyName);
              const doc1FirstAidMeasuresInfo = doc1.first_aid_measures
                ? decodeURIComponent(doc1.first_aid_measures)
                : '';
              const doc2ChemicalFriendlyName = decodeURIComponent(doc2.chemicalFriendlyName);
              const doc2FirstAidMeasuresInfo = doc2.first_aid_measures
                ? decodeURIComponent(doc2.first_aid_measures)
                : '';
              scope.docChemicalFriendlyName = {
                en:
                  $rootScope.siteLanguage === 'en'
                    ? doc1ChemicalFriendlyName
                    : doc2ChemicalFriendlyName,
                fr:
                  $rootScope.siteLanguage === 'fr'
                    ? doc1ChemicalFriendlyName
                    : doc2ChemicalFriendlyName
              };
              scope.docFirstAidMeasuresInfo = {
                en:
                  $rootScope.siteLanguage === 'en'
                    ? doc1FirstAidMeasuresInfo
                    : doc2FirstAidMeasuresInfo,
                fr:
                  $rootScope.siteLanguage === 'fr'
                    ? doc1FirstAidMeasuresInfo
                    : doc2FirstAidMeasuresInfo
              };
            }
          });
        }
        function isShowPpeInfoButton(doc) {
          if (
            isAdminUser &&
            (!scope.SearchPageService.companyId ||
              !($rootScope.companySettings && $rootScope.companySettings.enablePPE))
          ) {
            return !!(doc.ppe && doc.ppe.length);
          }
          return (
            $rootScope.labelBatchMode !== 'on' &&
            $rootScope.companySettings &&
            $rootScope.companySettings.enablePPE &&
            !!(doc['ppe_' + $rootScope.companyId] && doc['ppe_' + $rootScope.companyId].length)
          );
        }
        function showPPEInfo(doc) {
          return function () {
            const getPpeSelected = function () {
              if (
                isAdminUser &&
                (!scope.SearchPageService.companyId ||
                  !($rootScope.companySettings && $rootScope.companySettings.enablePPE))
              ) {
                return doc.ppe && doc.ppe.length ? doc.ppe : [];
              }
              return doc[`ppe_${$rootScope.companyId}`] && doc[`ppe_${$rootScope.companyId}`].length
                ? doc[`ppe_${$rootScope.companyId}`]
                : [];
            };
            const getPublicPpes = function () {
              return doc.ppe && doc.ppe.length ? doc.ppe : [];
            };
            ModalService.open(
              ppePresentationModal({ ppeSelected: getPpeSelected(), publicPpes: getPublicPpes() })
            );
          };
        }
        function getRelatedDocs() {
          let idList = 'id:' + scope.doc.id;
          const langVersionRegex = /VersionId$/;
          const langVersionIds = Object.keys(scope.doc).filter(val => langVersionRegex.test(val));
          langVersionIds.forEach(val => {
            idList = idList + ' OR id:' + scope.doc[val];
          });
          const options = { idList };
          SolrDocument.findByIdList(options).$promise.then(function (results) {
            const docs = results.response.docs.reduce((sum, curr) => {
              return { ...sum, [curr.id]: curr };
            }, {});
            scope.relatedDocs = docs;
            getPpeInfo();
          });
        }
        function getPpeInfo() {
          scope.isShowPpeInfoButton = false;
          scope.showPPEInfo = null;
          let docId;
          if (scope.doc[`${$rootScope.siteLanguage}VersionId`]) {
            docId = scope.doc[`${$rootScope.siteLanguage}VersionId`];
          } else {
            docId = scope.doc.id;
          }
          const currentDoc = scope.relatedDocs[docId];
          if (currentDoc) {
            scope.showPPEInfo = showPPEInfo(currentDoc);
            scope.isShowPpeInfoButton = isShowPpeInfoButton(currentDoc);
          }
        }
        $rootScope.$watch('siteLanguage', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          getPpeInfo();
        });
        $rootScope.$watch('labelBatchMode', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          getPpeInfo();
        });
        $rootScope.$watch('companyId', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          getPpeInfo();
        });
        scope.$watch('SearchPageService.companyId', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          getPpeInfo();
        });
      },
      controller: function () {
        this.isCollapsed = true;
      },
      controllerAs: 'ctrl'
    };
  }
]);
