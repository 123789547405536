const getWaterfallInventoryToAlert = require('./getWaterfallInventoryToAlert');
const { join: joinPath } = require('path');
const { LOCAL_VERSION } = require('../constants');

const normalizeKey = key => {
  return key ? (key instanceof Array ? key : key.split('.')) : [];
};

/**
 * Retrieve key from a hash.
 * e.g.:
 *   dig({foo:{bar:'baz'}}, 'foo.bar')
 *   dig({foo:{bar:'baz'}}, ['foo', 'bar'])
 * Params:
 *   - data - hash,
 *   - key - dot-separated string key chain or pre-split array,
 *   - defaultValue - to be returned when key doesn't exist.
 * Return: value or undefined.
 */
const dig = (data, key, defaultValue) => {
  const res = normalizeKey(key).reduce((sum, key) => {
    return sum ? sum[key] : undefined;
  }, data || {});
  return res === undefined ? defaultValue : res;
};

const compact = arr => arr.filter(x => x != null);

const toEpoch = date => Math.ceil(date.getTime() / 1000),
  toDayStart = date => {
    date.setUTCHours(0, 0, 0);
    return date;
  },
  toDayEnd = date => {
    date.setUTCHours(23, 59, 59);
    return date;
  },
  composeDateModifiedSelector = (day, span) => {
    const date = new Date(day);
    span = (span || '').toLowerCase();
    if (span === 'before') {
      return `dateModified:[* TO ${toEpoch(toDayStart(date))}]`;
    } else if (span === 'after') {
      return `dateModified:[${toEpoch(toDayEnd(date))} TO *]`;
    }
    return `dateModified:[${toEpoch(toDayStart(date))} TO ${toEpoch(toDayEnd(date))}]`;
  };

/**
 * @param string|number {epoch} in seconds.
 * @return string ISO8601
 */
const epoch2iso = epoch => {
  if (!epoch) {
    return null;
  }
  const ts = new Date(0);
  ts.setUTCSeconds(epoch);
  return ts.toISOString();
};

/**
 * Try parse date.
 * @param string {value}.
 * @return string ISO8601
 */
const any2iso = value => {
  if (!value) {
    return null;
  }
  const ms = Date.parse(value);
  if (!ms) {
    return null;
  }
  const ts = new Date(0);
  ts.setUTCMilliseconds(ms);
  return ts.toISOString();
};

const composeBoolSelector = (field, value) =>
  value == null ? null : [field, value ? 'true' : 'false'].join(':');

const composeFieldSelector = (field, value) => (value == null ? null : [field, value].join(':'));

/**
 * Make array out of text,
 * item per line.
 */
const arrayify = txt =>
  txt
    .split('\n')
    .map(x => x.trim())
    .filter(x => x);
const toArray = txt => arrayify(txt).join(',');

const asArray = value => (!value ? [] : Array.isArray(value) ? value : [value]);

const anID = value => /^[0-9a-z]{24}$/i.test(value);

const tryDecodeURIComponent = (value, errorLog = true) => {
  if (!value) {
    return value;
  }
  try {
    value = decodeURIComponent(value);
  } catch (err) {
    if (errorLog) {
      console.error(err);
    }
  }
  return value;
};

const composeStoragePath = (...args) => joinPath(__dirname, '../../', 'server', 'storage', ...args);

/**
 * Check the local version of website
 * @param {mixed} version Can be string US/CA or array ['US','CA']
 *
 * @returns {boolean}
 */
const accessLocalVersion = version => {
  const localVersionString = localVersion();

  return asArray(version).includes(localVersionString);
};

/**
 * Get local version of website
 * @returns {String}
 */
const localVersion = () => {
  return process.env.NODE_LOCAL_VERSION ? process.env.NODE_LOCAL_VERSION : LOCAL_VERSION;
};

const appLanguages = process.env.APP_LANGUAGES
  ? process.env.APP_LANGUAGES.split(',')
  : ['en', 'fr'];

/**
 * Replace multiple values
 * @param {String} str String to search
 * @param {Object} mapObj Object to replace
 *
 * @return {String}
 *
 * E.g. replaceAll('one two three', {
 *  one: 1,
 *  two: 2,
 *  three: 3
 * }) // 1 2 3 as result
 * Note, use map object lowercase for keys
 */
const replaceAll = (str, mapObj) => {
  let re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

/**
 * Make an key/value object by array
 *
 * @param {Array} arr an array of objects
 * @param {String} key object property to use as a key
 * @param {Function} cb callback function for result object element
 *
 * @returns {Object} returns an object with key/value properties
 * e.g. pivotArray([{id: 1, v: 1}, {id: 2, v: 2}], 'id', el => el.v+2);
 * returns { '1': 3, '2': 4 }
 */
const pivotArray = (arr, key = 'id', cb = el => el) =>
  arr.reduce((sum, obj) => {
    sum[obj[key]] = cb(obj);
    return sum;
  }, {});

const isUrl = (str = '') => {
  return /^https?:\/\//i.test(str);
};

/**
 * Returns a path to the tag with all of its parents as an array
 *
 * @param {Array<Resource>} companyTags
 * @param {string | ObjectId} tagId
 * @param {Array<string>} [tagPath=[]]
 * @returns {Array<string>}
 */
const getTagPath = function (companyTags, tagId, tagPath = []) {
  const tag = companyTags.find(t => t.id.toString() === tagId.toString());
  tagPath.unshift(tag.title);

  if (!tag.tagId) return tagPath;

  return getTagPath(companyTags, tag.tagId, tagPath);
};

module.exports = {
  dig,
  compact,
  composeDateModifiedSelector,
  composeBoolSelector,
  composeFieldSelector,
  getWaterfallInventoryToAlert,
  toArray,
  asArray,
  anID,
  arrayify,
  tryDecodeURIComponent,
  epoch2iso,
  any2iso,
  composeStoragePath,
  accessLocalVersion,
  localVersion,
  replaceAll,
  pivotArray,
  toEpoch,
  isUrl,
  appLanguages,
  getTagPath
};
