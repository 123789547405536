import app from '../app';

app.factory('SearchFactory', [
  '$rootScope',
  'SolrDocument',
  '$translate',
  function SearchFactory($rootScope, SolrDocument, $translate) {
    SearchFactory.translations = {};
    SearchFactory.hazardTypes = {};
    SearchFactory.numResults = 0;
    SearchFactory.documents = [];
    SearchFactory.status = '';
    SearchFactory.searchParams = {};
    SearchFactory.searchTerms = '';
    SearchFactory.totalPages = [];
    SearchFactory.searchByManufacturers = [];
    SearchFactory.searchByTags = [];
    SearchFactory.searchByLanguages = [];
    SearchFactory.languageSelected = {};
    SearchFactory.languages = [
      { value: 'en', title: 'COMMON.LANG.EN' },
      { value: 'fr', title: 'COMMON.LANG.FR' }
    ];

    SearchFactory.treeLanguages = [{ title: 'Languages', languages: SearchFactory.languages }];

    SearchFactory.advancedSearch = function (
      searchParams,
      searchByManufacturers,
      searchByTags,
      searchByLanguages,
      justSearch,
      callback
    ) {
      SearchFactory.status = 'searching';
      SearchFactory.searchByManufacturers = searchByManufacturers;
      SearchFactory.searchByTags = searchByTags;
      SearchFactory.searchByLanguages = searchByLanguages;

      SolrDocument.advancedSearch(searchParams).$promise.then(function (results) {
        var foundDocuments = [];

        SearchFactory.highlighting = results.highlighting;

        if (justSearch === true) {
          foundDocuments = FormatSearchResults(results.response.docs);
          addFilenames(foundDocuments);
        } else {
          SearchFactory.numResults = results.response.numFound;
          SearchFactory.documents = FormatSearchResults(results.response.docs);
          addFilenames(SearchFactory.documents);
          foundDocuments = SearchFactory.documents;
          SearchFactory.status = 'finished';
          SearchFactory.searchParams = searchParams;
          SearchFactory.totalPages = new Array(
            Math.ceil(SearchFactory.numResults / SearchFactory.searchParams.rows)
          );
        }

        callback && callback(foundDocuments);
      });
    };

    SearchFactory.showSynonymFor = function (docId) {
      let synonym = SearchFactory.highlighting[docId].synonyms || '';

      if (synonym)
        synonym =
          '[' +
          SearchFactory.translations['SEARCH.SYNONYM_TO'] +
          ' ' +
          decodeURIComponent(synonym) +
          ']';

      return synonym;
    };

    function FormatSearchResults(documents) {
      var newResults = [];
      for (var i = 0; i < documents.length; ++i) {
        var linkedIndex = -1;

        //all documents default to english and should have a en flag
        if (!documents[i].language) {
          documents[i].language = ['en'];
        }

        if (documents[i].enVersionId) {
          documents[i].language.push('en');
        }

        if (documents[i].frVersionId) {
          documents[i].language.push('fr');
        }

        //make sure language array is unique
        documents[i].language = arrayUnique(documents[i].language);

        //if english result shows up, make it the exclusive title
        if (documents[i].enVersionId && $rootScope.siteLanguage === 'en') {
          linkedIndex = GetLinkedIndexInSearchResults(documents[i].enVersionId, documents);
        } else if (documents[i].frVersionId && $rootScope.siteLanguage === 'fr') {
          linkedIndex = GetLinkedIndexInSearchResults(documents[i].frVersionId, documents);
        }

        if (~linkedIndex) {
          documents[i].hideFromResults = true;
          SearchFactory.numResults--;
        }

        if (documents[i].isArchived && !documents[i].hideFromResults) {
          documents[i].hideFromResults = true;
          SearchFactory.numResults--;
        }

        if (!documents[i].hideFromResults) {
          newResults.push(documents[i]);
        }
      }
      return newResults;
    }

    function GetLinkedIndexInSearchResults(linkedId, documents) {
      for (var i = 0; i < documents.length; ++i) {
        if (documents[i].id === linkedId) {
          return i;
        }
      }

      return -1;
    }

    function arrayUnique(array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }

      return a;
    }

    SearchFactory.reset = function () {
      SearchFactory.numResults = 0;
      SearchFactory.documents = [];
      SearchFactory.status = '';
      SearchFactory.searchParams = {};
    };

    // toggle selection for a given language
    SearchFactory.toggleLanguageSelected = function (language) {
      if (SearchFactory.languageSelected[language]) {
        SearchFactory.languageSelected[language] = !SearchFactory.languageSelected[language];
      } else {
        SearchFactory.languageSelected[language] = true;
      }
    };

    // set selection 'true' for a given language
    SearchFactory.setLanguageSelected = function (language) {
      SearchFactory.languageSelected[language] = true;
    };

    //convenience method to get an array of selected languages
    SearchFactory.getSelectedLanguages = function () {
      var selectedLanguages = [];
      for (var language in SearchFactory.languageSelected) {
        if (SearchFactory.languageSelected[language]) {
          selectedLanguages.push(language);
        }
      }

      return selectedLanguages;
    };

    SearchFactory.getSelectedHazards = function (hazards) {
      var selectedHazards = [];

      Object.keys(hazards).forEach(function (hazardName) {
        if (hazards[hazardName].checked) selectedHazards.push(hazards[hazardName].value);
      });

      return selectedHazards;
    };

    SearchFactory.getSelectedCodes = function (codes) {
      var selectedCodes = [];

      codes.forEach(function (code) {
        if (code.checked) selectedCodes.push(code.id);
      });

      return selectedCodes;
    };

    function addFilenames(documents) {
      documents.forEach(function (document) {
        document.filename = decodeURIComponent(document.resourcePath).replace(/^.*[\\/]/, '');
      });
    }

    SearchFactory.textTranslate = function () {
      $translate([
        'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.DESCRIPTION',
        'SEARCH.SYNONYM_TO'
      ]).then(
        function (translations) {
          var translate = {};

          for (var translation in translations) {
            translate[translation] = translations[translation];
          }

          setTranslations(translate);
        }
      );
    };

    // TODO: replace with HazardService
    function setTranslations(translate) {
      SearchFactory.hazardTypes['healthHazard'] = {
        value: 'healthHazard',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE']
      };
      SearchFactory.hazardTypes['flame'] = {
        value: 'flame',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE']
      };
      SearchFactory.hazardTypes['exclamationMark'] = {
        value: 'exclamationMark',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE']
      };
      SearchFactory.hazardTypes['corrosion'] = {
        value: 'corrosion',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE']
      };
      SearchFactory.hazardTypes['gasCylinder'] = {
        value: 'gasCylinder',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE']
      };
      SearchFactory.hazardTypes['explodingBomb'] = {
        value: 'explodingBomb',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE']
      };
      SearchFactory.hazardTypes['skullAndCrossbones'] = {
        value: 'skullAndCrossbones',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE']
      };
      SearchFactory.hazardTypes['flameOverCircle'] = {
        value: 'flameOverCircle',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE']
      };
      SearchFactory.hazardTypes['environment'] = {
        value: 'environment',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE']
      };
      SearchFactory.hazardTypes['biohazard'] = {
        value: 'biohazard',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE']
      };
      SearchFactory.hazardTypes['flame_old'] = {
        value: 'flame_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE']
      };
      SearchFactory.hazardTypes['exclamationMark_old'] = {
        value: 'exclamationMark_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE']
      };
      SearchFactory.hazardTypes['corrosion_old'] = {
        value: 'corrosion_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE']
      };
      SearchFactory.hazardTypes['gasCylinder_old'] = {
        value: 'gasCylinder_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE']
      };
      SearchFactory.hazardTypes['skullAndCrossbones_old'] = {
        value: 'skullAndCrossbones_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE']
      };
      SearchFactory.hazardTypes['flameOverCircle_old'] = {
        value: 'flameOverCircle_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE']
      };
      SearchFactory.hazardTypes['reactiveMaterials_old'] = {
        value: 'reactiveMaterials_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE']
      };
      SearchFactory.hazardTypes['biohazard_old'] = {
        value: 'biohazard_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE']
      };

      SearchFactory.translations = translate;
    }

    $rootScope.$on('$translateChangeSuccess', function () {
      SearchFactory.textTranslate();
    });

    return SearchFactory;
  }
]);

app.factory('SearchSettings', [
  'Tag',
  'TagFactory',
  function (Tag, TagFactory) {
    var searchSettings = {};
    searchSettings.UniversalTags = new TagFactory(Tag);
    searchSettings.CompanyTags = new TagFactory(Tag);
    searchSettings.CompanyDisposalCodes = [];
    searchSettings.CompanyStorageCodes = [];

    return searchSettings;
  }
]);
