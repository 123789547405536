import './standardSearch.html';
import { injectDependencies, reselectChildTag } from '../../../helpers';

const urlParamsToFilter = ['query', 'tag'];

export const standardSearch = function ($scope) {
  const [$rootScope, $state, SearchPageService, CompanyService, Tag, MessagesService] =
    injectDependencies([
      '$rootScope',
      '$state',
      'SearchPageService',
      'CompanyService',
      'Tag',
      'MessagesService'
    ]);
  const vm = this;
  vm.startCollapsed = true;
  vm.printState = false;
  vm.sps = SearchPageService;
  vm.sps.initSensitiveTags();
  vm.broaderSearch = $rootScope.accessToBroaderSearch();
  vm.isVisiblePpeSelector = function () {
    if (
      $rootScope.checkIfUserIs('admin') &&
      (!vm.sps.companyId || !($rootScope.companySettings && $rootScope.companySettings.enablePPE))
    ) {
      return !!(vm.sps.search.facets.ppe && vm.sps.search.facets.ppe.length);
    }
    if ($rootScope.companySettings && $rootScope.companySettings.enablePPE) {
      return !!(
        vm.sps.search.facets[`ppe_${$rootScope.companyId}`] &&
        vm.sps.search.facets[`ppe_${$rootScope.companyId}`].length
      );
    }
  };
  vm.getPpesForSelector = function () {
    if (
      $rootScope.checkIfUserIs('admin') &&
      (!vm.sps.companyId || !($rootScope.companySettings && $rootScope.companySettings.enablePPE))
    ) {
      return vm.sps.search.facets.ppe && vm.sps.search.facets.ppe.length
        ? vm.sps.search.facets.ppe
        : [];
    }
    if ($rootScope.companySettings && $rootScope.companySettings.enablePPE) {
      return vm.sps.search.facets[`ppe_${$rootScope.companyId}`] &&
        vm.sps.search.facets[`ppe_${$rootScope.companyId}`].length
        ? vm.sps.search.facets[`ppe_${$rootScope.companyId}`]
        : [];
    }
  };

  function quickFilterManu(manu) {
    return vm.sps.search.facets.manufacturer.some(function (el) {
      const ret = el.id === manu.id;
      if (ret) el.checked = true;
      return ret;
    });
  }

  function quickFilterHazard(hazard) {
    return vm.sps.search.facets.hazardType.some(function (el) {
      const ret = el.value === hazard;
      if (ret) el.checked = true;
      return ret;
    });
  }

  function quickFilterTag(tag) {
    const facetTag = vm.sps.search.facets.tag.find(t => t.id === tag.id);
    let resp = false;

    if (facetTag) {
      reselectChildTag(vm.sps.search.facets.tag, tag);

      facetTag.checked = true;
      resp = true;
    }

    return resp;
  }

  function quickFilterInventory(inv) {
    const facetEl = vm.sps.inventoryWithProducts.find(el => el.id === inv.id);
    let resp = false;

    if (facetEl) {
      facetEl.checked = true;
      resp = true;
    }

    return resp;
  }

  $scope.$on('onQuickFilter', function (event, params) {
    let haveValue;
    switch (params.filterClass) {
      case 'tag':
        haveValue = quickFilterTag(params.obj);
        break;
      case 'inventory':
        haveValue = quickFilterInventory(params.obj);
        break;
      case 'manu':
        haveValue = quickFilterManu(params.obj);
        break;
      case 'hazard':
        haveValue = quickFilterHazard(params.obj);
        break;
    }

    if (haveValue) {
      vm.sps.search.offset = 0;
      vm.sps.onSearch();
    }
  });

  $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
    if (from.name !== 'viewSds' && from.name !== 'process') {
      vm.sps.onReset();
      // have to pre-fetch otherwise tags don't get updated after initial hydration
      vm.sps.preFetch(true).then(function () {
        const state = $state.params.state ? JSON.parse($state.params.state) : null;
        vm.printState = state && state.print;
        if (state) {
          vm.sps.doSearch(state);
        } else if (urlParamsToFilter.some(param => $state.params[param])) {
          getStateFromParams($state.params).then(state => vm.sps.doSearch(state));
        } else {
          vm.sps.doSearch({ limit: vm.sps.search.docs.length ? vm.sps.search.limit : 0 });
        }
      });
    }
  });

  vm.onPrint = function () {
    const MAX_PRINT = 500; // the value must be able to be divided to PER_PAGE without remainder
    const totalRecords = vm.sps.search ? (vm.sps.search.meta ? vm.sps.search.meta.found : 0) : 0;
    const currentPage = vm.sps.search ? vm.sps.search.page : 1;
    const passedRecords = currentPage * vm.sps.PER_PAGE;
    const getUrl = function () {
        // NOTE: presence of query string messes things up
        return window.location.href;
        //return window.location.origin + window.location.pathname;
      },
      getState = function () {
        let payload = {
          print: true,
          query: vm.sps.search.query,
          facets: [],
          filters: vm.sps.search.filters.map(function (filter) {
            const preparedFilter = {
              id: filter.id,
              field: filter.field,
              value: filter.value || filter.id,
              name: filter.name
            };

            if (filter.field === 'tag') {
              preparedFilter.title = filter.title;
              preparedFilter.title_fr = filter.title_fr;
            }

            return preparedFilter;
          }),
          limit: MAX_PRINT,
          offset: vm.sps.search ? vm.sps.search.offset : 0,
          andOrStrategy: vm.sps.search.andOrStrategy
        };

        payload = vm.sps.addArchivedFilter(payload);

        return JSON.stringify(payload);
      },
      getCurrentUser = function () {
        return $rootScope.currentUser ? JSON.stringify($rootScope.currentUser) : null;
      };

    if (totalRecords - passedRecords >= MAX_PRINT) {
      MessagesService.warning(
        'SEARCH.PRINT.LIMIT_WARN',
        {
          limit: MAX_PRINT
        },
        {
          closeButton: true,
          timeOut: 0,
          extendedTimeOut: 0
        }
      );
    }

    window.open(
      '/print2pdf?state=' +
        encodeURIComponent(getState()) +
        '&url=' +
        getUrl() +
        '&currentUser=' +
        encodeURIComponent(getCurrentUser()) +
        '&printOpts=' +
        JSON.stringify({
          pageRanges: '',
          margin: {
            top: '0.5in',
            right: '0.18in',
            bottom: '0.42in',
            left: '0.19in'
          }
        }),
      '_blank'
    );
  };

  async function getStateFromParams(params) {
    const state = {
      query: params.query,
      filters: []
    };
    const paramsTag = params.tag ? [].concat(params.tag) : [];

    const company = (await CompanyService.getCurrentCompanyPromise().$promise)[0];
    state.filters.push({ field: 'companyId', value: company.id });

    if (paramsTag.length) {
      const tags = await Tag.find({ filter: { where: { id: { inq: paramsTag } } } }).$promise;
      tags.forEach(tag => state.filters.push({ field: 'tag', value: tag.id, title: tag.title }));
    }

    return state;
  }
};
