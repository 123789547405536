import app from '../../app';
import { waitForProp, calculatePages, getOffset, localVersion } from '../../helpers';
import scheduleReport from '../../modals/scheduleReport';

app.controller('gaReportsCtrl', [
  '$q',
  '$scope',
  '$rootScope',
  'GoogleAnalytics',
  'CompanyService',
  'SpinnerService',
  'MessagesService',
  'ExportService',
  'SchedulerService',
  'ModalService',
  'schoolDomains',
  'domains',
  function (
    $q,
    $scope,
    $rootScope,
    GoogleAnalytics,
    CompanyService,
    SpinnerService,
    MessagesService,
    ExportService,
    SchedulerService,
    ModalService,
    schoolDomains,
    domains,
  ) {
    const vm = this;
    vm.startFrom = 1;
    vm.PER_PAGE = 20;
    vm.paginationApi = {};

    $scope.startDate = '30daysAgo';
    $scope.endDate = 'today';
    $scope.gaData = {};
    $scope.today = new Date();
    $scope.isLoading = true;
    $scope.showDateRange = false;
    const GA_MAX_RESULTS = 10000;

    let selectedCompany = {};

    vm.handlePaginate = function () {
      reqData();
    };

    vm.handleExport = SpinnerService.wrap(function () {
      return makeQuery({ maxResults: GA_MAX_RESULTS }).then(function (response) {
        if (response.rows.length >= GA_MAX_RESULTS) {
          const totalPages = calculatePages($scope.gaData.totalItems, vm.PER_PAGE);
          const pagesArentIncluded = calculatePages(
            response.totalResults - response.rows.length,
            vm.PER_PAGE
          );
          const pageToGo = totalPages - pagesArentIncluded + 1;

          MessagesService.warning('REPORTS.GA.EXPORT_WARN', { pageToGo: pageToGo });
        }

        return ExportService.exportGA2csv(formatRows(response.rows));
      });
    });

    $scope.changeDate = function (date, datepickerDate) {
      if (!datepickerDate) {
        $scope.startDate = date;
        $scope.endDate = 'today';
        $scope.showDateRange = false;
      } else {
        $scope.showDateRange = true;

        if (date === 'start') {
          $scope.startDate = datepickerDate.format('YYYY-MM-DD');
        } else {
          $scope.endDate = datepickerDate.format('YYYY-MM-DD');
        }
      }

      vm.paginationApi.goToPage(1);
    };

    $scope.onCompanySelected = function (company = {}) {
      if (!company) return;

      if (company.id == null) {
        $scope.companyDomain = null;
      } else {
        selectedCompany = company;
        $scope.companyDomain = getCompanyDomain(company);
        $scope.gaTrackingId = company.gaTrackingId;
      }

      vm.paginationApi.goToPage(1);
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res => {
        if ($rootScope.checkIfUserIs('admin') && (!selectedCompany || !selectedCompany.id)) {
          return SchedulerService.createReportForAllCompanies(
            res,
            $scope.companies,
            null,
            createScheduledReport
          );
        } else {
          return createScheduledReport(res, $rootScope.companyId, {
            domain: getCompanyDomain(selectedCompany)
          });
        }
      });
    };

    init();

    function init() {

      if ($rootScope.checkIfUserIs('teamAdmin')) {
        CompanyService.getCurrentCompanyPromise()
          .$promise.then(function (company) {
            selectedCompany = company;
            $scope.companyDomain = getCompanyDomain(company);
            $scope.gaTrackingId = company.gaTrackingId;
          })
          .then(reqData);
      }

      $('.dropdown.keep-open').on({
        'shown.bs.dropdown': function () {
          this.closable = false;
        },
        'hide.bs.dropdown': function () {
          return this.closable;
        }
      });

      $('html').on({
        click: function (e) {
          var nodeName = e.target.nodeName;
          var parents = $(e.target).parentsUntil('#datepicker').length;
          var offsetParent = e.target.offsetParent ? e.target.offsetParent.nodeName : null;

          // console.log(nodeName);
          // console.log(parents);
          // console.log(offsetParent);

          if (
            (offsetParent !== null || nodeName === 'BODY' || nodeName === 'HTML') &&
            parents !== 14 &&
            nodeName !== 'TH' &&
            offsetParent !== 'TD' &&
            parents !== 15
          ) {
            var dropdown = $('.datepicker-dropdown')[0];
            dropdown && (dropdown.closable = true);
          }
        }
      });

      $scope.$on('$stateChangeStart', function (event) {
        $('html').off('click');
      });
    }

    function getCompanyDomain(company) {
      let domain = window.location.hostname;
      if (schoolDomains[company.name.toLowerCase()].indexOf(domain) === -1) {
        let filteredDomains = schoolDomains[company.name.toLowerCase()].filter(domain =>
          domain.includes(domains[$rootScope.localVersion])
        );
        domain = filteredDomains[0];
      }

      return domain;
    }

    function reqData() {
      $scope.isLoading = true;

      return makeQuery().then(function (response) {
        $scope.isLoading = false;

        if (response.rows) {
          response.rows = formatRows(response.rows);
          $scope.gaData = response;
          $scope.gaData.totalItems = response.totalResults;
        } else {
          $scope.gaData.rows = [];
          $scope.gaData.totalResults = 0;
        }
      });
    }

    function formatRows(rows) {
      return rows.reduce(function (formattedRows, row) {
        if (row[0] === '/') return formattedRows;

        const updatedRow = [row[0].replace(/^\//, ''), row[1]];
        return formattedRows.concat([updatedRow]);
      }, []);
    }

    function makeQuery(queryOptions = {}) {
      const offset = getOffset(vm.startFrom, vm.PER_PAGE);

      return GoogleAnalytics.getGA4Data({
        filter: {
          propertyId: queryOptions.gaTrackingId || $scope.gaTrackingId,
          domain: queryOptions.companyDomain || $scope.companyDomain,
          startDate: queryOptions.startDate || $scope.startDate,
          endDate: queryOptions.endDate || $scope.endDate,
          offset: queryOptions.startIndex || offset,
          limit: queryOptions.maxResults || vm.PER_PAGE,
        }
      }).$promise;
    }

    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      return SchedulerService.createReport(
        SchedulerService.jobs.report.PAGE_VIEW_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
