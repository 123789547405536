import angular from 'angular';
import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset } from '../../helpers';

app.controller('archivedSdsReportCtrl', [
  '$rootScope',
  '$scope',
  'CompanyFactory',
  'CompanyService',
  'SolrDocument',
  'ErrorService',
  'ExportService',
  'SpinnerService',
  'HazardService',
  'Tag',
  'TagFactory',
  'SchedulerService',
  'ModalService',
  'TagService',
  function (
    $rootScope,
    $scope,
    CompanyFactory,
    CompanyService,
    SolrDocument,
    ErrorService,
    ExportService,
    SpinnerService,
    HazardService,
    Tag,
    TagFactory,
    SchedulerService,
    ModalService,
    TagService
  ) {
    const vm = this;
    vm.HazardService = HazardService;
    vm.totalItems = 0;
    vm.companiesNames = {};

    function init() {
      vm.dataInit = true;
      vm.startFrom = 1;
      vm.PER_PAGE = 20;
      vm.companies = [];
      vm.docs = [];
      vm.selectedCompany = {};
      vm.tags = [];
      vm.archivedTagsIds = [];
      vm.archivedTagsCompany = {};
      vm.companiesNames = {};

      $rootScope.$watch('companies', function (newValue, oldValue) {
        vm.companiesNames = (newValue || []).reduce((all, el) => {
          all[el.id] = el.name;
          return all;
        }, {});
      });

      CompanyService.getCurrentCompanyPromise().$promise.then(function () {
        if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector
        
        vm.onCompanySelected($rootScope.currentCompany);
      });
      
    }
    
    /**
     * Get Report Data
     * @param {String} companyId Company Id
     * @param {Number} limit Rows number limit
     * @param {Number} offset Result offset
     * @param {Boolean} count Count Result
     * @returns 
     */
    async function getData(companyId, limit, offset = 0, count = false) {

      let filters = [ 
        `tag:(${vm.archivedTagsIds.join(' ')})`,
        `language: ${$rootScope.siteLanguage} OR mirrorSDS: ${$rootScope.siteLanguage}`
      ];
      if (companyId) {
        filters.push(`companyId:${companyId}`);
      }

      const optQuery = {
        query: '*',
        filters,
        includeArchived: true
      };

      if (!count) {
        optQuery.limit = limit;
        optQuery.offset = offset;
      } else {
        optQuery.limit = null;
        optQuery.offset = null;
      }

      const response = await SolrDocument.findSds(optQuery).$promise;
      
      return count ? response.meta.found : response.docs;
    }

    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      if (!vm.dataInit) {
        init();
      }
    });

    vm.getDocCompanies = (doc) => {
      const docArchivedTagsIds = doc.tag.filter(value => vm.archivedTagsIds.includes(value));
      return _.uniq(docArchivedTagsIds.map(el => vm.companiesNames[vm.archivedTagsCompany[el]])).join(', ');
    };

    vm.retrieve = SpinnerService.wrap(async function (limit, offset) {
      vm.docs = await getData(vm.selectedCompany.id, limit, offset);
      $scope.$apply();
    });

    vm.count = function () {
      getData(vm.selectedCompany.id, null, null, true)
        .then(function (data) {
          vm.totalItems = data;
          $scope.$apply();
        })
        .catch(ErrorService.simpleCatch);
    };

    vm.handleExport = SpinnerService.wrap(function () {
      return getData(vm.selectedCompany.id, 2147483647 /*max int32*/)
        .then(function (data) {
          for(let i in data) {
            data[i].archivedTagsCompany = vm.getDocCompanies(data[i]);
          }
          return ExportService.exportArchivedSdsReport2csv(data);
        })
        .catch(ErrorService.simpleCatch);
    });

    vm.handlePaginate = function () {
      vm.retrieve(vm.PER_PAGE, getOffset(vm.startFrom, vm.PER_PAGE));
    };

    vm.onCompanySelected = async function (selectedCompany) {
      vm.selectedCompany = selectedCompany;
      vm.startFrom = 1;
      vm.tags = vm.selectedCompany.id
        ? await TagService.getCompanyTags(vm.selectedCompany.id)
        : await TagService.getAll();
      
      vm.archivedTagsIds = vm.tags.filter(el => el.archived).map(el => el.id);
      vm.archivedTagsCompany = vm.tags.filter(el => el.archived).reduce((all, el) => {
        all[el.id] = el.companyId;
        return all;
      }, {});

      vm.count();

      return vm.retrieve(vm.PER_PAGE, 0);
    };

    vm.openScheduleModal = function () {
      return ModalService.open(scheduleReport()).then(res => {
        const companyId = vm.selectedCompany ? vm.selectedCompany.id : null;
        return createScheduledReport(res, companyId);
      });
    };
    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      return SchedulerService.createReport(
        SchedulerService.jobs.report.ARCHIVED_SDS_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
