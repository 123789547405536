import app from '../../app';

app.controller('linkDocumentCtrl', [
  '$scope',
  '$state',
  'SolrDocument',
  'SearchPageService',
  'SpinnerService',
  'ErrorService',
  'ModalService',
  function (
    $scope,
    $state,
    SolrDocument,
    SearchPageService,
    SpinnerService,
    ErrorService,
    ModalService
  ) {
    //setup the viewmodel
    var vm = this;

    vm.sps = SearchPageService;
    vm.previousVersions = [];

    const getPreviousVersions = SpinnerService.wrap(function (id) {
      return SolrDocument.getPreviousVersions({ id: id })
        .$promise.then(function (resp) {
          vm.previousVersions = resp.previousVersions || [];
        })
        .catch(ErrorService.simpleCatch);
    });
    vm.getFilename = function (path) {
      return decodeURIComponent(path).replace(/^.*[\\/]/, '');
    };

    //company
    $scope.currentCompanyId = '';

    $scope.unlinkPreviousVersion = SpinnerService.wrap(function (id) {
      return SolrDocument.unlink({ id: id })
        .$promise.then(function (resp) {
          vm.sdsDocument.previousVersionId = resp.previousVersionId;
        })
        .then(function () {
          return getPreviousVersions(vm.sdsDocument.id);
        });
    });
    $scope.promptUnlinkPreviousVersion = function (id) {
      return ModalService.open({
        templateUrl: 'areYouSure.html'
      }).then(function () {
        return $scope.unlinkPreviousVersion(id);
      });
    };

    $scope.linkPreviousVersion = SpinnerService.wrap(function (previousVersionId) {
      if (previousVersionId == vm.sdsDocument.id) return;

      return SolrDocument.addLink({
        id: vm.sdsDocument.id,
        previousVersionId: previousVersionId
      }).$promise.then(function (resp) {
        vm.sdsDocument.previousVersionId = resp.versions[0].id;
        vm.previousVersions = resp.versions || [];
      });
    });

    $scope.linkLanguageVersion = SpinnerService.wrap(function (languageKey, languageVersionId) {
      return SolrDocument.addLangLink({
        id: vm.sdsDocument.id,
        langVerId: languageVersionId,
        lang: languageKey,
        backLink: true
      })
        .$promise.then(function (resp) {
          vm.sdsDocument[languageKey] = languageVersionId;

          return SolrDocument.notify(
            { actionName: 'added' },
            { companyIds: vm.sdsDocument.companyId, sdsIds: [languageVersionId] }
          ).$promise;
        })
        .catch(ErrorService.simpleCatch);
    });

    $scope.removeLanguageVersion = SpinnerService.wrap(function (languageKey, languageVersionId) {
      return SolrDocument.removeLangLink({
        id: vm.sdsDocument.id,
        lang: languageKey
      })
        .$promise.then(function (resp) {
          vm.sdsDocument[languageKey] = '';
        })
        .catch(ErrorService.simpleCatch);
    });

    function archiveDocument(documentId, callback) {
      if (!documentId) {
        return callback({ message: 'no documentId' });
      }

      var documentModel = createDocumentModel(documentId);
      documentModel['isArchived'] = { set: 'true' };

      saveDocumentModel(documentModel, function (err, result) {
        if (err) {
          callback(err);
        } else {
          callback(null, result);
        }
      });
    }

    function unArchiveDocument(documentId, callback) {
      if (!documentId) {
        return callback({ message: 'no documentId' });
      }
      var documentModel = createDocumentModel(documentId);
      documentModel['isArchived'] = { set: 'false' };
      saveDocumentModel(documentModel, function (err, result) {
        if (err) {
          callback(err);
        } else {
          callback(null, result);
        }
      });
    }

    //creates a document model that can be used to save data to the database.
    //set is required by solr to update fields in the database.
    //only fields that exist in solr can be a part of the document model.
    function createDocumentModel(documentId) {
      var documentModel = {};
      documentModel.id = documentId;
      return documentModel;
    }

    function saveDocumentModel(documentModel, callback) {
      //create post data
      var postData = { data: '[' + JSON.stringify(documentModel) + ']' };

      SolrDocument.update(
        postData,
        function (results) {
          callback(null, results);
        },
        function (err) {
          callback(err);
        }
      );
    }

    //populate the search form with data from the previous search
    function InitilizeSearchForm() {
      vm.sps.onReset();
      // have to pre-fetch otherwise tags don't get updated after initial hydration
      vm.sps.preFetch(true).then(function () {
        const state = $state.params.state ? JSON.parse($state.params.state) : null;
        if (state) {
          vm.sps.doSearch(state);
        }
      });
    }

    function GetDocumentById(documentId) {
      vm.sdsDocument = {};
      var searchId = { idList: 'id:' + documentId };
      return SolrDocument.findByIdList(searchId).$promise.then(function (results) {
        var document = results.response.docs[0];
        vm.sdsDocument.chemicalFriendlyName = decodeURIComponent(document.chemicalFriendlyName);
        vm.sdsDocument.issueDate = decodeURIComponent(document.issueDate);
        vm.sdsDocument.id = documentId;
        vm.sdsDocument.previousVersionId = document.previousVersionId;
        vm.sdsDocument.enVersionId = document.enVersionId;
        vm.sdsDocument.frVersionId = document.frVersionId;
        vm.sdsDocument.companyId = document.companyId;
        vm.sdsDocument.language = document.language || [];

        if (vm.sdsDocument.chemicalFriendlyName == 'undefined') {
          vm.sdsDocument.chemicalFriendlyName = '';
        }
        if (vm.sdsDocument.issueDate == 'undefined') {
          vm.sdsDocument.issueDate = '';
        }
      });
    }

    //setup the search page
    function Initialize() {
      InitilizeSearchForm();

      if ($state.params.id) {
        GetDocumentById($state.params.id).then(function () {
          return getPreviousVersions($state.params.id);
        });
      }
    }

    Initialize();
  }
]);
