import angular from 'angular';
import app from '../app';

app.service('AddedRemovedSdsReportService', [
  '$rootScope',
  'SpinnerService',
  'JournalChange',
  'ExportService',
  function ($rootScope, SpinnerService, JournalChange, ExportService) {
    const vm = this;

    vm.reqData = SpinnerService.wrap(function ($scope) {
      const params = angular.extend({
        dateFrom: $scope.date.start,
        dateTo: $scope.date.end,
        filter: {
          where: {
            'data.include': ['Any', 'tagsChange'].includes($scope.radioModel)
              ? undefined
              : $scope.radioModel === 'Include',
            'data.tags': $scope.radioModel === 'tagsChange' ? true : undefined,
            'data.companyId': !$scope.selectedCompany.id
              ? undefined
              : { objectId: $scope.selectedCompany.id }
          }
        },
        tagIds: $scope.tagIds || [],
        inventoryIds: ($scope.selectedInventory || []).map(el => el.id),
        lang: $rootScope.siteLanguage
      });
      return JournalChange.getChanging_SDS_Companies(params)
        .$promise.then(function (resp) {
          $scope.docs = resp;
        })
        .catch(($scope.docs = []));
    });

    vm.handleExport = function ($scope) {
      ExportService.exportAddedRemovedSds($scope.docs, { day: $scope.date.end });
    };

    vm.caseDateFromName = function (name, start) {
      let res = 0;
      const day = start ? new Date(start) : new Date();
      switch (name) {
        case 'today':
          res = day; // 30 days
          break;
        case '30daysAgo':
          res = day - 2592000000; // 30 days
          break;
        case '60daysAgo':
          res = day - 5184000000; // 60 days
          break;
        case '90daysAgo':
          res = day - 7776000000; // 90 days
          break;
        default:
          res = new Date(name);
          break;
      }

      return new Date(res);
    };
    vm.evalDate = function (date) {
      const today = new Date();
      let dateFromName = vm.caseDateFromName(date.startStr);
      dateFromName.setHours(0, 0, 0, 0);
      date.start = dateFromName;
      dateFromName = vm.caseDateFromName(date.endStr);
      dateFromName.setHours(23, 59, 59, 999);
      date.end = dateFromName;
    };
  }
]);
